import React from 'react';
import PropTypes from 'prop-types';

import I18n from '../base/i18n';

const CancelAndSaveButton = ({
  onSave,
  disabled,
  onCancel,
  userCanModifyRemark
}) => {
  if (!userCanModifyRemark) return <></>;

  return (
    <div className="row justify-content-end mt-1 mb-3">
      <div className="col-auto">
        <button
          type="button"
          className="btn btn-xs btn-outline-secondary me-1"
          onClick={onCancel}
        >
          {I18n.t('cancel')}
        </button>

        <button
          type="button"
          disabled={disabled}
          className="btn btn-xs btn-primary"
          onClick={onSave}
        >
          {I18n.t('save')}
        </button>
      </div>
    </div>
  );
};

CancelAndSaveButton.propTypes = {
  onSave: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  userCanModifyRemark: PropTypes.bool.isRequired
};

export default CancelAndSaveButton;
