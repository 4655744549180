import React from 'react';
import PropTypes from 'prop-types';

const ParentSelector = ({
  records,
  getLabel,
  onSelect,
  disabled,
  selectedRecord
}) => (
  <div className="col-6">
    <select
      multiple
      onChange={onSelect}
      disabled={disabled}
      value={[selectedRecord]}
      className="form-control h-100"
    >
      {Object.keys(records).map((key) => (
        <option key={key} value={key}>
          {getLabel(records[key])}
        </option>
      ))}
    </select>
  </div>
);

ParentSelector.defaultProps = {
  selectedRecord: undefined
};

ParentSelector.propTypes = {
  selectedRecord: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  getLabel: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  records: PropTypes.instanceOf(Object).isRequired
};

export default ParentSelector;
