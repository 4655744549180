import PropTypes from 'prop-types';
import React, { useCallback } from 'react';

import User from './user';

const Users = ({ users, setUsers }) => {
  const afterActivateUser = useCallback(
    (index) => {
      setUsers([...users.slice(0, index), ...users.slice(index + 1)]);
    },
    [users, setUsers]
  );

  const afterAnonymizeUser = useCallback(
    (user, index) => {
      setUsers([...users.slice(0, index), ...users.slice(index + 1), user]);
    },
    [users, setUsers]
  );

  return (
    <div className="card">
      <div className="card-body">
        <h5>
          <span className="fa5-text">
            {I18n.t('archives.users.title.other')}
          </span>
        </h5>
        {users.length ? (
          <table className="table table-hover align-middle">
            <thead>
              <tr>
                <th>{I18n.t('users.name_or_email')}</th>
                <th aria-label="Actions" />
              </tr>
            </thead>
            <tbody>
              {users.map((user, index) => (
                <User
                  user={user}
                  key={user.id}
                  index={index}
                  afterActivateUser={afterActivateUser}
                  afterAnonymizeUser={afterAnonymizeUser}
                />
              ))}
            </tbody>
          </table>
        ) : (
          <div className="text-center">
            {I18n.t('archives.users.no_archives')}
          </div>
        )}
      </div>
    </div>
  );
};

Users.propTypes = {
  setUsers: PropTypes.func.isRequired,
  users: PropTypes.instanceOf(Object).isRequired
};

export default Users;
