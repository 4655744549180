import React from 'react';
import PropTypes from 'prop-types';

const SuccessAlert = ({ successAlerts }) => (
  <>
    <div
      className="alert alert-dismissible fade show alert-success"
      role="alert"
    >
      <button
        type="button"
        className="btn-close"
        data-bs-dismiss="alert"
        aria-label="Close"
      />
      {successAlerts}
    </div>
  </>
);

SuccessAlert.defaultProps = {
  successAlerts: undefined
};

SuccessAlert.propTypes = {
  successAlerts: PropTypes.string
};

export default SuccessAlert;
