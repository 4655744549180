import axios from 'axios';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';
import React, { useState, useEffect, useCallback } from 'react';

import AsyncSelectInput from '../commons/formFields/asyncSelectInput';

const SearchProjects = ({
  meta,
  multi,
  input,
  onChange,
  clearable,
  className,
  searchable,
  placeholder,
  cacheOptions
}) => {
  const [defaultOptions, setDefaultOptions] = useState();

  const search = useCallback((query, callback) => {
    axios
      .get(`/projects?name=${query}`, {
        headers: { accept: 'application/json' }
      })
      .then(({ data }) => {
        const options = data.map((project) => ({
          value: project.id,
          label: project.name
        }));
        callback(options);
      });
  }, []);

  useEffect(() => {
    if (!defaultOptions) search('', setDefaultOptions);
  }, [defaultOptions]);

  let handleProjectSearch = (value, callback) => {
    const query = value.trim();
    if (!query.length) {
      callback([]);
      return;
    }
    search(query, callback);
  };

  handleProjectSearch = debounce(handleProjectSearch, 300);

  return (
    <AsyncSelectInput
      meta={meta}
      multi={multi}
      input={input}
      onChange={onChange}
      className={className}
      clearable={clearable}
      searchable={searchable}
      placeholder={placeholder}
      cacheOptions={cacheOptions}
      defaultOptions={defaultOptions}
      loadOptions={handleProjectSearch}
    />
  );
};

SearchProjects.defaultProps = {
  multi: false,
  meta: undefined,
  clearable: false,
  input: undefined,
  searchable: false,
  cacheOptions: false,
  onChange: undefined,
  className: undefined,
  placeholder: undefined
};

SearchProjects.propTypes = {
  multi: PropTypes.bool,
  onChange: PropTypes.func,
  clearable: PropTypes.bool,
  searchable: PropTypes.bool,
  className: PropTypes.string,
  cacheOptions: PropTypes.bool,
  placeholder: PropTypes.string,
  input: PropTypes.instanceOf(Object),
  meta: PropTypes.objectOf(PropTypes.any)
};

export default SearchProjects;
