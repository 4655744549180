// TODO: why reason and remarks are living in different components? They look the same to me.
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import I18n from '../base/i18n';
import axios from '../base/axios';
import TextArea from '../base/textarea';
import CancelAndSaveButton from './CancelAndSaveButton';
import UserSignature from '../commons/user_signature';
import CommentBadge from '../commons/comment_badge';

const Reason = ({
  remove,
  reasonableId,
  reasonableType,
  reason: propReason,
  userCanModifyReason,
  lastReasonOfReasonable
}) => {
  const [hidden, setHidden] = useState(false);
  const [loading, setLoading] = useState(false);
  const [reason, setReason] = useState(propReason);
  const [editMode, setEditMode] = useState(!reason);
  const [text, setText] = useState(reason?.text || '');

  const save = () => {
    setLoading(true);
    if (reason) {
      // update
      axios({
        method: 'patch',
        url: `/reasons/${reason.id}`,
        data: {
          text
        }
      }).then(({ data }) => {
        setReason(data);
        setEditMode(false);
        setLoading(false);
      });
    } else {
      // create
      axios({
        method: 'post',
        url: '/reasons',
        data: {
          text,
          reasonable_id: reasonableId,
          reasonable_type: reasonableType
        }
      }).then(({ data }) => {
        setReason(data);
        setEditMode(false);
        setLoading(false);
      });
    }
  };

  const hide = () => {
    if (confirm(I18n.t('are_you_sure'))) {
      setHidden(true);
      remove();
    }
  };

  const deleteRequest = () => {
    setLoading(true);
    axios({
      method: 'delete',
      url: `/reasons/${reason.id}`,
      headers: { accept: 'application/javascript' }
    }).then((data) => {
      setHidden(true);
      remove();
      setLoading(false);
      // execute server side js
      eval(data.data);
    });
  };

  const deleteReason = () => {
    if (lastReasonOfReasonable) {
      if (confirm(I18n.t('reasons.delete.last.are_you_sure'))) {
        deleteRequest();
      }
    } else if (confirm(I18n.t('are_you_sure'))) {
      deleteRequest();
    }
  };

  if (hidden) return <></>;

  const noText = text.trim() === '';

  if (!reason) {
    return (
      <>
        <TextArea text={text} noText={noText} setText={setText} />

        <CancelAndSaveButton
          onSave={save}
          onCancel={hide}
          disabled={noText || loading}
          userCanModifyReason={userCanModifyReason}
        />
      </>
    );
  }

  if (editMode) {
    return (
      <>
        <TextArea text={text} noText={noText} setText={setText} />

        <CancelAndSaveButton
          onSave={save}
          disabled={noText || loading}
          onCancel={() => setEditMode(false)}
          userCanModifyReason={userCanModifyReason}
        />
      </>
    );
  }

  // display mode
  return (
    <>
      <div className={`displayable${userCanModifyReason ? ' mb-1' : ''}`}>
        <div
          className="displayable-text"
          dangerouslySetInnerHTML={{ __html: reason.display_text }}
        />
        <div className="date-label">
          {reason.date}
          <div className="text-end">
            {reason.comment_type && (
              <CommentBadge commentType={reason.comment_type} />
            )}
            {reason.display_user_signature && (
              <UserSignature
                userAvatarUrl={reason.user_avatar_url}
                userShortNameOrEmail={reason.user_short_name_or_email}
                userFullNameOrEmail={reason.user_full_name_or_email}
              />
            )}
          </div>
        </div>
      </div>

      {userCanModifyReason && (
        <div className="row justify-content-end mb-3">
          <div className="col-auto">
            <div className="btn-group opacity-75">
              <button
                type="button"
                onClick={() => setEditMode(true)}
                className="btn btn-xs btn-outline-primary"
                data-tippy-content={I18n.t('edit')}
              >
                <i className="fa fa-pen fa-sm" />
              </button>

              <button
                type="button"
                onClick={() => deleteReason()}
                className="btn btn-xs btn-outline-danger"
                data-tippy-content={I18n.t('destroy')}
              >
                <i className="fa fa-trash fa-sm" />
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

Reason.defaultProps = {
  reason: null
};

Reason.propTypes = {
  reason: PropTypes.instanceOf(Object),
  remove: PropTypes.func.isRequired,
  reasonableId: PropTypes.string.isRequired,
  reasonableType: PropTypes.string.isRequired,
  userCanModifyReason: PropTypes.bool.isRequired,
  lastReasonOfReasonable: PropTypes.bool.isRequired
};

export default Reason;
