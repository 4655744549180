import I18n from 'i18n-js';

const { defaultLocale, locale, translations } = window.I18n;

I18n.fallbacks = true;
I18n.defaultLocale = defaultLocale;
I18n.locale = locale;
I18n.translations = translations;

export default I18n;
