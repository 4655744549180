import React from 'react';
import PropTypes from 'prop-types';

const Chapter = ({ id, label, checked, onCheckboxChange }) => (
  <>
    <div className="form-check">
      <label htmlFor={id}>
        <input
          id={id}
          name={label}
          type="checkbox"
          checked={checked}
          onChange={onCheckboxChange}
          className="form-check-input"
        />
        {label}
      </label>
    </div>
  </>
);

Chapter.propTypes = {
  id: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  onCheckboxChange: PropTypes.func.isRequired
};

export default Chapter;
