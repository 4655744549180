import PropTypes from 'prop-types';
import React, { useState, useCallback } from 'react';

import axios from '../base/axios';

const EventTrigger = ({ index, eventTrigger, removeEventTrigger }) => {
  const [busy, setBusy] = useState(false);

  const activateEventTrigger = useCallback(() => {
    if (busy) return;

    if (confirm(I18n.t('are_you_sure'))) {
      setBusy(true);
      axios
        .put(
          `/event_triggers/${eventTrigger.id}/toggle_archived`,
          { archived: false },
          { headers: { accept: 'application/json' } }
        )
        .then(() => removeEventTrigger(index))
        .catch(() => setBusy(false));
    }
  });

  const deleteEventTrigger = useCallback(() => {
    if (busy) return;

    if (confirm(I18n.t('cannot_be_undone'))) {
      setBusy(true);
      axios
        .delete(`/event_triggers/${eventTrigger.id}`)
        .then(() => removeEventTrigger(index))
        .catch(() => setBusy(false));
    }
  });

  return (
    <tr>
      <td>{eventTrigger.name}</td>
      <td>{eventTrigger.event_trigger_type}</td>
      <td className="text-end">
        <button
          type="button"
          disabled={busy}
          aria-hidden="true"
          onClick={activateEventTrigger}
          className="btn btn-primary btn-sm"
        >
          {I18n.t('activate')}
        </button>
        <button
          type="button"
          disabled={busy}
          aria-hidden="true"
          onClick={deleteEventTrigger}
          className="btn btn-danger btn-sm ms-2"
        >
          {I18n.t('destroy')}
        </button>
      </td>
    </tr>
  );
};

EventTrigger.propTypes = {
  index: PropTypes.number.isRequired,
  removeEventTrigger: PropTypes.func.isRequired,
  eventTrigger: PropTypes.instanceOf(Object).isRequired
};

export default EventTrigger;
