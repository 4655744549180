import axios from 'axios';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';
import React, { useState, useEffect, useCallback } from 'react';

import AsyncSelectInput from '../commons/formFields/asyncSelectInput';

const SearchInstances = ({
  meta,
  multi,
  input,
  onChange,
  clearable,
  className,
  searchable,
  placeholder,
  disabledInstance,
  cacheOptions
}) => {
  const [defaultOptions, setDefaultOptions] = useState();

  const search = useCallback((query, callback) => {
    axios
      .get(`/instances?name=${query}`, {
        headers: { accept: 'application/json' }
      })
      .then(({ data }) => {
        const options = data.map((instance) => ({
          value: instance.id,
          label: instance.name
        }));
        callback(options);
      });
  }, []);

  useEffect(() => {
    if (!defaultOptions) search('', setDefaultOptions);
  }, [defaultOptions]);

  let handleInstanceSearch = (value, callback) => {
    const query = value.trim();
    if (!query.length) {
      callback([]);
      return;
    }
    search(query, callback);
  };

  handleInstanceSearch = debounce(handleInstanceSearch, 300);

  return (
    <AsyncSelectInput
      meta={meta}
      multi={multi}
      input={input}
      onChange={onChange}
      className={className}
      clearable={clearable}
      searchable={searchable}
      placeholder={placeholder}
      cacheOptions={cacheOptions}
      defaultOptions={defaultOptions}
      loadOptions={handleInstanceSearch}
      disabled={disabledInstance}
    />
  );
};

SearchInstances.defaultProps = {
  multi: false,
  meta: undefined,
  clearable: false,
  input: undefined,
  searchable: false,
  cacheOptions: false,
  onChange: undefined,
  className: undefined,
  placeholder: undefined,
  disabledInstance: false
};

SearchInstances.propTypes = {
  multi: PropTypes.bool,
  onChange: PropTypes.func,
  clearable: PropTypes.bool,
  searchable: PropTypes.bool,
  className: PropTypes.string,
  cacheOptions: PropTypes.bool,
  placeholder: PropTypes.string,
  input: PropTypes.instanceOf(Object),
  meta: PropTypes.objectOf(PropTypes.any),
  disabledInstance: PropTypes.bool
};

export default SearchInstances;
