import PropTypes from 'prop-types';
import React, { useState, useCallback } from 'react';

import axios from '../base/axios';

const User = ({ index, user, afterActivateUser, afterAnonymizeUser }) => {
  const [busy, setBusy] = useState(false);

  const activateUser = useCallback(() => {
    if (busy) return;

    if (confirm(I18n.t('are_you_sure'))) {
      setBusy(true);
      axios
        .put(
          `/users/${user.id}/toggle_activation`,
          { is_active: true },
          { headers: { accept: 'application/json' } }
        )
        .then(() => afterActivateUser(index))
        .catch(() => setBusy(false));
    }
  });

  const anonymizeUser = useCallback(() => {
    if (busy) return;

    if (confirm(I18n.t('cannot_be_undone'))) {
      setBusy(true);
      axios
        .put(`/users/${user.id}/anonymize`)
        .then(({ data }) => afterAnonymizeUser(data, index))
        .finally(() => setBusy(false));
    }
  });

  return (
    <tr>
      <td>
        <span className="user-avatar-name" title={user.title}>
          <img
            src={user.avatar_url}
            alt={user.full_name_or_email}
            className="avatar avatar-small"
          />
          {user.full_name_or_email}
        </span>
      </td>
      {!user.anonymous && (
        <td className="text-end">
          <button
            type="button"
            disabled={busy}
            aria-hidden="true"
            onClick={activateUser}
            className="btn btn-primary btn-sm"
          >
            {I18n.t('activate')}
          </button>
          <button
            type="button"
            disabled={busy}
            aria-hidden="true"
            onClick={anonymizeUser}
            className="btn btn-danger btn-sm ms-2"
          >
            {I18n.t('anonymize')}
          </button>
        </td>
      )}
    </tr>
  );
};

User.propTypes = {
  index: PropTypes.number.isRequired,
  afterActivateUser: PropTypes.func.isRequired,
  afterAnonymizeUser: PropTypes.func.isRequired,
  user: PropTypes.instanceOf(Object).isRequired
};

export default User;
