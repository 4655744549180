import React, { useState } from 'react';
import I18n from '../base/i18n';
import axios from '../base/axios';
import {
  DOCUMENT,
  WEBSITE,
  ITTOOL,
  RESPONSIBILITY,
  KPI,
  INTERFACE
} from './Items';

const Item = (props) => {
  const { item_type, key_element_id, remove, process_groups, index } = props;

  const [item, setItem] = useState(props.item);
  const [editMode, setEditMode] = useState(!item);
  const [label, setLabel] = useState((item && item.label) || '');
  const [link, setLink] = useState((item && item.link) || 'https://');
  const [remark, setRemark] = useState((item && item.remark) || '');
  const [formula, setFormula] = useState((item && item.formula) || '');
  const [value, setValue] = useState((item && item.value) || '');
  const [objective, setObjective] = useState((item && item.objective) || '');
  const [process_group1, setProcessGroup1] = useState(
    (item && item.process_group1) || (process_groups[0] && process_groups[0].id)
  );
  const [process_group2, setProcessGroup2] = useState(
    (item && item.process_group2) || (process_groups[0] && process_groups[0].id)
  );
  const [interface_agreement, setInterfaceAgreement] = useState(
    (item && item.interface_agreement) || false
  );
  const [hidden, setHidden] = useState(false);
  const [loading, setLoading] = useState(false);

  const selected_process_group_1 = process_groups.find(
    (pg) => pg.id == process_group1
  );
  const selected_process_group_2 = process_groups.find(
    (pg) => pg.id == process_group2
  );

  const COLUMN_LABELS = [
    I18n.t('key_elements.items.document_label_placeholder'),
    I18n.t('key_elements.items.website_label_placeholder'),
    I18n.t('key_elements.items.tool_label_placeholder'),
    I18n.t('key_elements.items.board_label_placeholder'),
    I18n.t('key_elements.items.kpi_label_placeholder'),
    I18n.t('key_elements.items.interface_agreement_label_placeholder')
  ];

  const save = () => {
    setLoading(true);
    const data = {
      item_type,
      label,
      link,
      remark,
      formula,
      value,
      objective,
      process_group1,
      process_group2,
      interface_agreement
    };
    if (item) {
      // update
      axios({
        method: 'patch',
        url: `/key_elements/${key_element_id}/items/${item.id}`,
        data: {
          item: data
        }
      }).then(({ data: item }) => {
        setItem(item);
        setEditMode(false);
        setLoading(false);
      });
    } else {
      // create
      axios({
        method: 'post',
        url: `/key_elements/${key_element_id}/items`,
        data: {
          item: data
        }
      }).then(({ data: item }) => {
        setItem(item);
        setEditMode(false);
        setLoading(false);
      });
    }
  };

  const deleteItem = () => {
    if (confirm(I18n.t('are_you_sure'))) {
      setLoading(true);
      axios({
        method: 'delete',
        url: `/key_elements/${key_element_id}/items/${item.id}`
      }).then(() => {
        setHidden(true);
        remove();
        setLoading(false);
      });
    }
  };

  if (hidden) return <></>;

  const documentbox = (
    <input
      className="col-12 mt-2 form-control"
      placeholder={I18n.t('key_elements.items.document_label_placeholder')}
      onChange={(e) => setLabel(e.target.value)}
      value={label}
    />
  );

  const website = (
    <input
      className="col-12 mt-2 form-control"
      placeholder={I18n.t('key_elements.items.website_label_placeholder')}
      onChange={(e) => setLabel(e.target.value)}
      value={label}
    />
  );

  const tool = (
    <input
      className="col-12 mt-2 form-control"
      placeholder={I18n.t('key_elements.items.tool_label_placeholder')}
      onChange={(e) => setLabel(e.target.value)}
      value={label}
    />
  );

  const board = (
    <input
      className="col-12 mt-2 form-control"
      placeholder={I18n.t('key_elements.items.board_label_placeholder')}
      onChange={(e) => setLabel(e.target.value)}
      value={label}
    />
  );

  const kpi = (
    <input
      className="col-12 mt-2 form-control"
      placeholder={I18n.t('key_elements.items.kpi_label_placeholder')}
      onChange={(e) => setLabel(e.target.value)}
      value={label}
    />
  );

  const linkbox = (
    <input
      type="url"
      className="col-12 mt-2 form-control"
      placeholder={I18n.t('key_elements.items.link_placeholder')}
      onChange={(e) => setLink(e.target.value)}
      value={link}
    />
  );

  const remarkbox = (
    <textarea
      className="col-12 mt-2 form-control"
      placeholder={I18n.t('key_elements.items.remark_placeholder')}
      onChange={(e) => setRemark(e.target.value)}
      value={remark}
    />
  );

  const formulabox = (
    <textarea
      className="col-12 mt-2 form-control"
      placeholder={I18n.t('key_elements.items.formula_label_placeholder')}
      onChange={(e) => setFormula(e.target.value)}
      value={formula}
    />
  );

  const valuebox = (
    <input
      className="col-12 mt-2 form-control"
      placeholder={I18n.t('key_elements.items.value_label_placeholder')}
      onChange={(e) => setValue(e.target.value)}
      value={value}
    />
  );

  const objectivebox = (
    <input
      className="col-12 mt-2 form-control"
      placeholder={I18n.t('key_elements.items.objective_label_placeholder')}
      onChange={(e) => setObjective(e.target.value)}
      value={objective}
    />
  );

  const labelbox = (
    <input
      className="col-12 mt-2 form-control"
      placeholder={I18n.t(
        'key_elements.items.interface_agreement_label_placeholder'
      )}
      onChange={(e) => setLabel(e.target.value)}
      value={label}
    />
  );

  const options = (process_groups) =>
    process_groups.map(({ id, name, level, children }) => (
      <>
        <option key={id} value={id}>
          {'-->'.repeat(level)}
          {name}
        </option>
        {options(children)}
      </>
    ));

  const process_group_1_box = (
    <div className="row align-items-center mt-2">
      <div className="col-auto align-middle">
        <label className="col-form-label ps-0">
          {I18n.t('key_elements.items.select process 1')}
        </label>
      </div>
      <div className="col">
        <select
          className="form-select"
          value={process_group1}
          onChange={(e) => setProcessGroup1(e.target.value)}
        >
          {options(process_groups.filter((pg) => pg.level == 0))}
        </select>
      </div>
    </div>
  );

  const process_group_2_box = (
    <div className="row align-items-center mt-2">
      <div className="col-auto align-middle">
        <label className="col-form-label ps-0">
          {I18n.t('key_elements.items.select process 2')}
        </label>
      </div>
      <div className="col">
        <select
          className="form-select"
          value={process_group2}
          onChange={(e) => setProcessGroup2(e.target.value)}
        >
          {options(process_groups.filter((pg) => pg.level == 0))}
        </select>
      </div>
    </div>
  );

  const interface_agreement_box = (
    <div className="form-check col-12 m-2">
      <input
        className="form-check-input"
        type="checkbox"
        checked={interface_agreement}
        onChange={() => setInterfaceAgreement(!interface_agreement)}
        id={`flexCheckDefault${index}`}
      />
      <label className="form-check-label" htmlFor={`flexCheckDefault${index}`}>
        {I18n.t('key_elements.items.interface agreement')}
      </label>
    </div>
  );

  let inputs = <div>default inputs</div>;

  switch (item_type) {
    case DOCUMENT:
      inputs = (
        <>
          {documentbox}
          {linkbox}
          {remarkbox}
        </>
      );
      break;
    case WEBSITE:
      inputs = (
        <>
          {website}
          {linkbox}
          {remarkbox}
        </>
      );
      break;
    case ITTOOL:
      inputs = (
        <>
          {tool}
          {linkbox}
          {remarkbox}
        </>
      );
      break;
    case RESPONSIBILITY:
      inputs = (
        <>
          {board}
          {linkbox}
          {remarkbox}
        </>
      );
      break;
    case KPI:
      inputs = (
        <>
          {kpi}
          {linkbox}
          {valuebox}
          {objectivebox}
          {formulabox}
        </>
      );
      break;
    case INTERFACE:
      inputs = (
        <>
          {process_group_1_box}
          {process_group_2_box}
          {interface_agreement_box}
          {labelbox}
          {linkbox}
          {remarkbox}
        </>
      );
    default:
      break;
  }

  if (!item) {
    const buttons = (
      <div className="mt-2 mb-3">
        <button
          className="btn btn-danger float-end"
          onClick={() => setHidden(true)}
        >
          {I18n.t('cancel')}
        </button>
        <button
          disabled={loading}
          className="btn btn-success float-end me-2"
          onClick={save}
        >
          {I18n.t('add')}
        </button>
      </div>
    );
    return (
      <div className="row">
        {inputs}
        {buttons}
      </div>
    );
  }

  if (editMode) {
    const buttons = (
      <div className="mt-2 mb-3">
        <button
          className="btn btn-danger float-end"
          onClick={() => setEditMode(false)}
        >
          {I18n.t('cancel')}
        </button>
        <button
          disabled={loading}
          className="btn btn-success float-end me-2"
          onClick={save}
        >
          {I18n.t('save')}
        </button>
      </div>
    );
    return (
      <>
        {inputs}
        {buttons}
      </>
    );
  }

  // display mode
  return (
    <div className="bg-light rounded-3 mb-3 px-3 py-2">
      {item_type == INTERFACE && (
        <div className="row">
          <div className="col-auto rounded bg-white m-2 p-2">
            <span className="text-secondary fw-lighter">
              {I18n.t('key_elements.items.process_1')}
            </span>
            <br />
            <a
              href={selected_process_group_1 && selected_process_group_1.link}
              className="overflow-auto"
              target="_blank"
              rel="noreferrer"
            >
              {selected_process_group_1 && selected_process_group_1.name}
            </a>
          </div>
          <div className="col-auto rounded bg-white m-2 p-2">
            <span className="text-secondary fw-lighter">
              {I18n.t('key_elements.items.process_2')}
            </span>
            <br />
            <a
              href={selected_process_group_2 && selected_process_group_2.link}
              className="overflow-auto"
              target="_blank"
              rel="noreferrer"
            >
              {selected_process_group_2 && selected_process_group_2.name}
            </a>
          </div>
          <div className="col-auto rounded bg-white m-2 p-2">
            <span className="text-secondary fw-lighter">
              {I18n.t('key_elements.items.interface agreement')}
            </span>
            <br />
            {(interface_agreement && <i className="fas fa-check me-2" />) || (
              <i className="fas fa-times me-2" />
            )}
            {(interface_agreement && I18n.t('yes')) || I18n.t('no')}
          </div>
        </div>
      )}
      <div className="row">
        <div className="col-6 rounded bg-white m-2 p-2">
          <span className="text-secondary fw-lighter">
            {COLUMN_LABELS[item_type]}
          </span>
          <br />
          {item.link ? (
            <a
              href={item.link}
              className="overflow-auto"
              target="_blank"
              rel="noreferrer"
            >
              {item.label}
            </a>
          ) : (
            <div>{item.label}</div>
          )}
        </div>
        {(item_type == KPI && (
          <>
            <div className="col rounded bg-white m-2 p-2 overflow-auto">
              <span className="text-secondary fw-lighter">
                {I18n.t('key_elements.items.value_label_placeholder')}
              </span>
              <br />
              {item.value}
            </div>
            <div className="col rounded bg-white m-2 p-2">
              <span className="text-secondary fw-lighter">
                {I18n.t('key_elements.items.objective_label_placeholder')}
              </span>
              <br />
              {item.objective}
            </div>
          </>
        )) || (
          <div className="col rounded bg-white m-2 p-2 overflow-auto pre">
            <span className="text-secondary fw-lighter">
              {I18n.t('key_elements.items.remark_placeholder')}
            </span>
            <br />
            {item.remark}
          </div>
        )}
      </div>
      {item_type == KPI && (
        <div className="row">
          <div className="col-6 rounded bg-white m-2 p-2 overflow-auto pre">
            <span className="text-secondary fw-lighter">
              {I18n.t('key_elements.items.formula_label_placeholder')}
            </span>
            <br />
            {item.formula}
          </div>
        </div>
      )}
      <div className="btn-group float-end">
        <button
          className="btn btn-outline-primary"
          data-tippy-content={I18n.t('edit')}
          onClick={() => setEditMode(true)}
        >
          <i className="fas fa-pen" />
        </button>
        <button
          className="btn btn-outline-danger"
          data-tippy-content={I18n.t('destroy')}
          onClick={() => {
            deleteItem();
          }}
        >
          <i className="fas fa-trash-alt" />
        </button>
      </div>
    </div>
  );
};

export default Item;
