import React from 'react';
import PropTypes from 'prop-types';

const UserSignature = ({
  userAvatarUrl,
  userFullNameOrEmail,
  userShortNameOrEmail
}) => (
  <>
    <span
      className="user-avatar-name me-0"
      data-tippy-content={userFullNameOrEmail}
    >
      <img className="avatar avatar-small" src={userAvatarUrl} alt="" />
      <span className="text-uppercase">{userShortNameOrEmail}</span>
    </span>
  </>
);

UserSignature.defaultProps = {
  userAvatarUrl: null,
  userFullNameOrEmail: null,
  userShortNameOrEmail: null
};

UserSignature.propTypes = {
  userAvatarUrl: PropTypes.string,
  userFullNameOrEmail: PropTypes.string,
  userShortNameOrEmail: PropTypes.string
};

export default UserSignature;
