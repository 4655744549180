import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

const Project = ({ project }) => {
  const renderSubProjects = useCallback(() => (
    <div className="col">
      <div className="children card h-100">
        <div className="card-header">{I18n.t('projects.subprojects')}</div>
        <div className="card-body">
          <ul>
            {project.sub_projects.map((subProject) => (
              <li key={subProject.id}>
                <a href={`/projects/?id=${subProject.id}`}>{subProject.name}</a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  ));

  const renderRequirementGroups = useCallback(() => (
    <div className="col">
      <div className="children card h-100">
        <div className="card-header">
          {I18n.t('activerecord.models.requirement_group.other')}
        </div>
        <div className="card-body">
          <ul>
            {project.requirement_groups.map((requirementGroup) => (
              <li key={requirementGroup.id}>
                <a href={`/requirement_groups/${requirementGroup.id}`}>
                  {requirementGroup.name}
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  ));

  const renderCustomers = useCallback(() => (
    <div className="col">
      <div className="children card h-100">
        <div className="card-header">
          {I18n.t('activerecord.models.customer.other')}
        </div>
        <div className="card-body">
          <ul>
            {project.customers.map((customer) => (
              <li key={customer.id}>
                <a href={`/requirement_groups/${customer.id}`}>
                  {customer.name}
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  ));

  return (
    <div>
      <h4 className="mb-3">{project.name}</h4>
      <div>
        <p className="mb-3">
          {project.instance
            ? `${I18n.t('activerecord.models.instance.one')}: ${
                project.instance.name
              }`
            : ''}
        </p>
      </div>
      <div className="row row-cols-1 row-cols-md-2 g-4">
        {renderSubProjects()}
        {renderRequirementGroups()}
        {renderCustomers()}
      </div>
    </div>
  );
};

Project.propTypes = {
  project: PropTypes.instanceOf(Object).isRequired
};

export default Project;
