import PropTypes from 'prop-types';
import React, { useState, useCallback } from 'react';

import axios from '../base/axios';

const Attachment = ({ index, attachment, removeAttachment }) => {
  const [busy, setBusy] = useState(false);

  const activateAttachment = useCallback(() => {
    if (busy) return;

    if (confirm(I18n.t('are_you_sure'))) {
      setBusy(true);
      axios
        .put(`/attachments/${attachment.id}/toggle_archived`, {
          archived: false
        })
        .then(() => removeAttachment(index))
        .catch(() => setBusy(false));
    }
  });

  const deleteAttachment = useCallback(() => {
    if (busy) return;

    if (confirm(I18n.t('cannot_be_undone'))) {
      setBusy(true);
      axios
        .delete(`/attachments/${attachment.id}`)
        .then(() => removeAttachment(index))
        .catch(() => setBusy(false));
    }
  });

  return (
    <tr>
      <td>{attachment.file_file_name}</td>
      <td>{attachment.assessment_question?.shortcode}</td>
      <td>{attachment.instance.name}</td>
      <td className="text-end">
        <button
          type="button"
          disabled={busy}
          aria-hidden="true"
          onClick={activateAttachment}
          className="btn btn-primary btn-sm"
        >
          {I18n.t('activate')}
        </button>
        <button
          type="button"
          disabled={busy}
          aria-hidden="true"
          onClick={deleteAttachment}
          className="btn btn-danger btn-sm ms-2"
        >
          {I18n.t('destroy')}
        </button>
      </td>
    </tr>
  );
};

Attachment.propTypes = {
  index: PropTypes.number.isRequired,
  removeAttachment: PropTypes.func.isRequired,
  attachment: PropTypes.instanceOf(Object).isRequired
};

export default Attachment;
