import React from 'react';
import PropTypes from 'prop-types';

const List = ({ item_key, item_value, onClick }) => (
  <>
    <li
      key={item_key}
      className="list-group-item d-flex justify-content-between align-items-center"
    >
      {onClick ? (
        <>
          {item_value}
          <a href="#" id={item_key} onClick={onClick}>
            X
          </a>
        </>
      ) : (
        <b>{item_value}</b>
      )}
    </li>
  </>
);

List.defaultProps = {
  onClick: null
};

List.propTypes = {
  onClick: PropTypes.func,
  item_key: PropTypes.number.isRequired,
  item_value: PropTypes.string.isRequired
};

export default List;
