import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

import Footer from './Footer';
import I18n from '../base/i18n';
import Chapters from './Chapters';
import NormPicker from './NormPicker';
import ErrorBoundary from '../base/error_boundary';
import UnorderedList from '../commons/unordered_list';

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      norms: [],
      chapters: [],
      currentNorm: null,
      selectedNormsChapters: [],
      normsHash: props.normChaptersHash,
      outputFieldId: props.outputFieldId,
      selectedNorms: props.selectedNorms,
      selectedChapters: props.selectedChapters
    };

    I18n.locale = $('body').data('locale');
  }

  selectedNormIndex = (normId) => {
    const { selectedNorms } = this.state;

    return selectedNorms.indexOf(normId);
  };

  selectedNormChapters = () => {
    const result = [];
    const { normsHash } = this.state;

    normsHash.forEach((norm) => {
      if (this.selectedNormIndex(norm.id) >= 0) {
        const selectedChapters = norm.chapters.filter((chapter) =>
          this.chapterIsSelected(chapter.id)
        );
        const anySelectedChapters = Object.keys(selectedChapters).length > 0;

        if (anySelectedChapters) {
          result.push({
            id: norm.id,
            name: norm.name,
            chapters: selectedChapters
          });
        }
      }
    });

    return result;
  };

  renderSelectedNormChapters = () => {
    let element = '';
    const { selectedNormsChapters } = this.state;

    if (selectedNormsChapters.length === 0) {
      element = (
        <small>
          <i>{I18n.t('target_maturity_answers.modal.add_norm_chapter')}</i>
        </small>
      );
    } else {
      element = (
        <UnorderedList
          norms={selectedNormsChapters}
          onClick={this.onClickChapterRemove}
          chapterIsSelected={this.chapterIsSelected}
        />
      );
    }

    ReactDOM.render(element, document.getElementById('entity-group-chapters'));
  };

  componentDidMount = () => {
    const { normsHash } = this.state;

    const norms = normsHash.map((norm) => ({
      id: norm.id,
      name: norm.name
    }));

    this.setState(
      { norms, selectedNormsChapters: this.selectedNormChapters() },
      () => {
        this.renderSelectedNormChapters();
      }
    );
  };

  saveClicked = () => {
    const { outputFieldId } = this.state;

    this.setState(
      { selectedNormsChapters: this.selectedNormChapters() },
      () => {
        const { selectedChapters } = this.state;
        $(outputFieldId).val(selectedChapters);
        this.renderSelectedNormChapters();
      }
    );
  };

  onClickChapterRemove = (event) => {
    event.preventDefault();
    const { selectedChapters } = this.state;
    const targetId = parseInt(event.target.id, 10);
    const chapters = selectedChapters.filter((id) => id !== targetId);

    this.setState({ selectedChapters: chapters }, () => this.saveClicked());
  };

  onCheckboxChange = (event) => {
    this.toggleCheckbox(parseInt(event.target.id, 10));
  };

  toggleCheckbox = (targetId) => {
    let { selectedChapters } = this.state;
    const chapterAlreadySelected = selectedChapters.some(
      (chapterId) => targetId === chapterId
    );

    if (chapterAlreadySelected) {
      selectedChapters = selectedChapters.filter((id) => id !== targetId);
    } else {
      selectedChapters.push(targetId);
      const { currentNorm, selectedNorms } = this.state;

      if (this.selectedNormIndex(currentNorm) < 0) {
        this.setState({ selectedNorms: [...selectedNorms, currentNorm] });
      }
    }

    this.setState({ selectedChapters });
  };

  chapterIsSelected = (chapterId) => {
    const { selectedChapters } = this.state;

    return selectedChapters.some((id) => chapterId === id);
  };

  onNormChange = (event) => {
    const { normsHash } = this.state;
    const normId = parseInt(event.target.value, 10);
    const norm = normsHash.find((normHash) => normHash.id === normId);
    const chapters = norm ? norm.chapters : [];

    this.setState({ chapters, currentNorm: normId });
  };

  render() {
    const { norms, chapters } = this.state;

    return (
      <ErrorBoundary>
        <div className="modal-body">
          <NormPicker norms={norms} onNormChange={this.onNormChange} />

          <Chapters
            chapters={chapters}
            onCheckboxChange={this.onCheckboxChange}
            chapterIsSelected={this.chapterIsSelected}
          />
        </div>

        <Footer onClick={this.saveClicked} />
      </ErrorBoundary>
    );
  }
}

Home.defaultProps = {
  selectedNorms: [],
  selectedChapters: [],
  normChaptersHash: []
};

Home.propTypes = {
  outputFieldId: PropTypes.string.isRequired,
  selectedNorms: PropTypes.arrayOf(PropTypes.number),
  selectedChapters: PropTypes.arrayOf(PropTypes.number),
  normChaptersHash: PropTypes.arrayOf(PropTypes.object)
};

export default Home;
