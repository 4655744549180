import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import AsyncSelect from 'react-select/async';
import I18n from '../../base/i18n';

const AsyncSelectInput = ({
  meta,
  input,
  multi,
  onBlur,
  onFocus,
  disabled,
  onChange,
  clearable,
  searchable,
  loadOptions,
  placeholder,
  cacheOptions,
  defaultOptions
}) => {
  I18n.locale = $('body').data('locale');
  const change = useCallback(
    (event) => {
      onChange?.(event);
      input?.onChange?.(event);
    },
    [input, onChange]
  );

  const blur = useCallback(
    (event) => {
      onBlur?.(event);
      input?.onBlur?.(event);
    },
    [input, onBlur]
  );

  const focus = useCallback(
    (event) => {
      onFocus?.(event);
      input?.onFocus?.(event);
    },
    [input, onFocus]
  );

  const NoOptionsMessage = () => (
    <div className="text-center p-2">
      {input?.name === 'requirement_groups'
        ? I18n.t('csr.no_requirement_group_options')
        : I18n.t('no_options')}
      {input?.name === 'requirement_groups' && (
        <div>
          <small>{I18n.t('csr.create_requirement_group')}</small>
          <br />
          <button
            type="button"
            className="btn btn-secondary btn-sm mt-2"
            data-bs-toggle="modal"
            data-bs-target="#csrEmailModal"
          >
            {I18n.t('csr.generate_email')}
          </button>
        </div>
      )}
    </div>
  );

  return (
    <>
      <AsyncSelect
        onBlur={blur}
        isMulti={multi}
        onFocus={focus}
        onChange={change}
        name={input?.name}
        value={input?.value}
        isDisabled={disabled}
        isClearable={clearable}
        placeholder={placeholder}
        loadOptions={loadOptions}
        isSearchable={searchable}
        cacheOptions={cacheOptions}
        defaultOptions={defaultOptions}
        components={{ NoOptionsMessage }}
      />
      {meta.error && meta.touched && (
        <p className="field_with_errors">
          <label>{meta.error}</label>
        </p>
      )}
      {meta.submitError && (
        <p className="field_with_errors">
          <label>{meta.submitError}</label>
        </p>
      )}
    </>
  );
};

AsyncSelectInput.defaultProps = {
  meta: {},
  input: {},
  multi: false,
  placeholder: '',
  disabled: false,
  clearable: false,
  searchable: false,
  onBlur: undefined,
  onFocus: undefined,
  cacheOptions: true,
  defaultOptions: [],
  onChange: undefined,
  loadOptions: undefined
};

AsyncSelectInput.propTypes = {
  multi: PropTypes.bool,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  clearable: PropTypes.bool,
  searchable: PropTypes.bool,
  loadOptions: PropTypes.func,
  cacheOptions: PropTypes.bool,
  placeholder: PropTypes.string,
  input: PropTypes.instanceOf(Object),
  meta: PropTypes.objectOf(PropTypes.any),
  defaultOptions: PropTypes.instanceOf(Array)
};

export default AsyncSelectInput;
