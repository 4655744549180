import React from 'react';
import PropTypes from 'prop-types';

import List from './list';

const UnorderedList = ({ norms, onClick, chapterIsSelected }) => (
  <ul className="list-group" style={{ maxWidth: `${50}%` }}>
    {norms.map((norm) => (
      <span key={norm.id}>
        <List key={norm.id} item_key={norm.id} item_value={norm.name} />

        {norm.chapters.map((chapter) =>
          chapterIsSelected(chapter.id) ? (
            <List
              key={chapter.id}
              onClick={onClick}
              item_key={chapter.id}
              item_value={chapter.title}
            />
          ) : (
            ''
          )
        )}
      </span>
    ))}
  </ul>
);

UnorderedList.propTypes = {
  onClick: PropTypes.func.isRequired,
  chapterIsSelected: PropTypes.func.isRequired,
  norms: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default UnorderedList;
