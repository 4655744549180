import React from 'react';
import PropTypes from 'prop-types';

const ErrorAlert = ({ errorAlerts }) => (
  <>
    <div className="row">
      <div className="col-auto">
        <div
          className="alert alert-dismissible fade show alert-danger"
          role="alert"
        >
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="alert"
            aria-label="Close"
          />
          {errorAlerts}
        </div>
      </div>
    </div>
  </>
);

ErrorAlert.defaultProps = {
  errorAlerts: undefined
};

ErrorAlert.propTypes = {
  errorAlerts: PropTypes.string
};

export default ErrorAlert;
