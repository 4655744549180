import PropTypes from 'prop-types';
import React, { useState, useEffect, useCallback } from 'react';

import axios from '../base/axios';

import Attachment from './attachment';

const Attachments = ({
  objectId,
  objectType,
  userMayAssess,
  noAttachmentText,
  acceptedMimeTypes
}) => {
  const [attachments, setAttachments] = useState();

  const loadAttachments = useCallback(() => {
    axios
      .get(`/attachments?object_id=${objectId}&object_type=${objectType}`)
      .then(({ data }) => setAttachments(data));
  }, []);

  useEffect(() => {
    if (!attachments) loadAttachments();
  }, [attachments, loadAttachments]);

  const removeAttachment = useCallback(
    (idx) => {
      setAttachments([
        ...attachments.slice(0, idx),
        ...attachments.slice(idx + 1)
      ]);
    },
    [attachments]
  );

  const createAttachments = useCallback(
    (e) => {
      if (!e.target.files.length) return;

      const formData = new FormData();
      Array.from(e.target.files).forEach((file) => {
        formData.append('attachments[]', file);
      });
      axios
        .post(
          `/attachments?object_id=${objectId}&object_type=${objectType}`,
          formData
        )
        .then(({ data }) => setAttachments([...attachments, ...data]));
    },
    [attachments]
  );

  return (
    <div className="assessmentAnswer-attachments">
      <h5>
        <i className="far fa-file me-2 fa-xs" />
        <span className="fa5-text">Attachments</span>
        {userMayAssess && (
          <div
            className="btn btn-secondary btn-xs ms-1 assessmentAnswer-buttonUpload"
            data-tippy-content="Add attachment"
            data-tippy-placement="right"
          >
            <i className="fas fa-file-upload" />
            <input
              type="file"
              id="attachments_"
              multiple="multiple"
              accept={acceptedMimeTypes}
              onChange={createAttachments}
            />
          </div>
        )}
      </h5>
      <div className="assessmentAnswer-attachments">
        <div className="ms-4">
          {attachments?.length ? (
            <ul
              className={`assessmentAnswer-attachmentsList mt-2 mb-3${
                userMayAssess ? '' : ' no-edit'
              }`}
            >
              {attachments.map((attachment, idx) => (
                <Attachment
                  idx={idx}
                  key={attachment.id}
                  attachment={attachment}
                  userMayAssess={userMayAssess}
                  removeAttachment={removeAttachment}
                />
              ))}
            </ul>
          ) : (
            <div className="assessmentAnswer-attachmentsText">
              <p>
                <i>{noAttachmentText}</i>
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

Attachments.propTypes = {
  objectId: PropTypes.string.isRequired,
  objectType: PropTypes.string.isRequired,
  userMayAssess: PropTypes.bool.isRequired,
  noAttachmentText: PropTypes.string.isRequired,
  acceptedMimeTypes: PropTypes.string.isRequired
};

export default Attachments;
