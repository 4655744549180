import PropTypes from 'prop-types';
import { Form, Field } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';
import React, { useState, useRef, useCallback } from 'react';

import SearchInstances from '../instances/search';
import SearchCustomers from '../customers/search';
import TextInput from '../commons/formFields/textInput';
import SearchRequirementGroups from '../requirementGroups/search';
import ShowProjects from '../requirementGroups/projects';
import ErrorAlert from '../commons/alerts/error_alert';

const ProjectForm = ({
  project,
  formMode,
  saveProject,
  setFormMode,
  selectedProjectId,
  errorAlerts,
  setErrorAlerts
}) => {
  const options = (data) =>
    data.map((details) => ({
      value: details.id,
      label: details.name
    }));

  const [selectedRequirementGroups, setSelectedRequirementGroups] = useState(
    []
  );
  const getInitialValues = useCallback(() => {
    const {
      id,
      name,
      instance,
      customers,
      requirement_groups: requirementGroups
    } = project;

    let obj = {
      id,
      name,
      instance: instance ? options([instance]) : [],
      customers: options(customers),
      requirement_groups: options(requirementGroups)
    };

    if (formMode === 'createChild') {
      obj.id = undefined;
      obj.name = '';
      obj.parent_id = selectedProjectId;
    }

    if (formMode === 'create') {
      obj = {};
    }
    return obj;
  }, [project, selectedProjectId]);

  const [disabledInstance, setDisabledInstance] = useState();
  const [disabledCustomer, setDisabledCustomer] = useState();
  const [instancePrompt, setInstancePrompt] = useState();
  const [customersPrompt, setCustomersPrompt] = useState();

  const validateCustomers = (customers) => {
    if (disabledCustomer) return [];

    return customers ? customers.map(({ value }) => value) : [];
  };

  const validateInstance = (instance) => {
    if (disabledInstance) return [];

    const instanceVal = instance || instance[0];
    return instanceVal ? [instanceVal.value] : instance;
  };

  const validateRequirementGroupAndProjects = (
    requirementGroupAndProjectIds
  ) => {
    if (requirementGroupAndProjectIds === undefined) return [];

    const projectIds = Object.values(requirementGroupAndProjectIds);
    const requirementGroupIds = Object.keys(requirementGroupAndProjectIds);
    const requirementGroupAndProject = [];

    if (requirementGroupIds.length === projectIds.length) {
      requirementGroupIds.map((requirementGroup, index) =>
        requirementGroupAndProject.push([requirementGroup, projectIds[index]])
      );
    }

    return requirementGroupAndProject;
  };

  const onSubmit = useCallback((values) => {
    const {
      id,
      name,
      parent_id: parentID,
      instance,
      customers,
      requirement_groups: requirementGroups,
      requirement_group_and_project_ids: requirementGroupAndProjectIds
    } = values;

    const obj = {
      id,
      name,
      parent_id: parentID,
      instance_id: validateInstance(instance),
      customer_ids: validateCustomers(customers),
      requirement_group_ids: requirementGroups
        ? requirementGroups.map(({ value }) => value)
        : [],
      requirement_group_and_project_ids: validateRequirementGroupAndProjects(
        requirementGroupAndProjectIds
      )
    };
    return saveProject(obj);
  });

  const handleInstanceChange = useCallback((field) => {
    const data = field ? setDisabledCustomer(true) : setDisabledCustomer(false);
    setInstancePrompt(I18n.t('projects.errors.instance_warning'));
    setCustomersPrompt('');
    return data;
  });

  const handleCustomerChange = useCallback((field) => {
    const data =
      field.length === 0
        ? setDisabledInstance(false)
        : setDisabledInstance(true);
    setCustomersPrompt(I18n.t('projects.errors.customer_warning'));
    setInstancePrompt('');
    return data;
  });

  const initialValues = useRef(getInitialValues(project));
  const isParent = formMode === 'create' || formMode === 'edit';
  const isEditable = formMode === 'edit' || formMode === 'editChild';
  const hideFieldForSubprojects = isParent || project.instance == null;
  const isCreateModeWithRequirementGroups =
    formMode === 'create' && selectedRequirementGroups.length > 0;
  return (
    <div>
      <h4 className="mb-3">
        {I18n.t(isEditable ? 'projects.edit' : 'projects.new', {
          project: project?.name
        })}
      </h4>
      <Form
        onSubmit={onSubmit}
        initialValues={initialValues.current}
        render={({ invalid, submitting, handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            {errorAlerts && <ErrorAlert errorAlerts={errorAlerts} />}
            <div className="mb-3 field">
              <label className="form-label" htmlFor="project-name">
                {I18n.t('name')}
              </label>
              <Field
                name="name"
                component={TextInput}
                className="form-control"
                validate={(value) =>
                  value ? undefined : I18n.t('required_field')
                }
              />
            </div>
            {isParent && (
              <div className="mb-3 field">
                <label
                  className="form-label"
                  htmlFor="project-requirement-groups"
                >
                  {I18n.t('activerecord.models.instance.one')}
                </label>
                <Field
                  clearable
                  searchable
                  cacheOptions
                  disabledInstance={disabledInstance}
                  name="instance"
                  className="form-control"
                  component={SearchInstances}
                  onChange={handleInstanceChange}
                />
                <p className="field_with_errors">
                  <label>{customersPrompt}</label>
                </p>
              </div>
            )}
            {hideFieldForSubprojects && (
              <div className="mb-3 field">
                <label
                  className="form-label"
                  htmlFor="project-requirement-groups"
                >
                  {I18n.t('activerecord.models.customer.other')}
                </label>
                <Field
                  multi
                  clearable
                  searchable
                  cacheOptions
                  name="customers"
                  className="form-control"
                  disabledCustomer={disabledCustomer}
                  component={SearchCustomers}
                  onChange={handleCustomerChange}
                />
                <p className="field_with_errors">
                  <label>{instancePrompt}</label>
                </p>
              </div>
            )}
            <div className="mb-3 field csr-requirement-group">
              <label
                className="form-label"
                htmlFor="project-requirement-groups"
              >
                {I18n.t('csr.requirements')}
              </label>
              <Field
                multi
                clearable
                searchable
                cacheOptions
                className="form-control"
                name="requirement_groups"
                component={SearchRequirementGroups}
                csrType="projects"
              />
              <OnChange name="requirement_groups">
                {(values) => setSelectedRequirementGroups(values)}
              </OnChange>
            </div>
            {isCreateModeWithRequirementGroups && (
              <ShowProjects requirementGroups={selectedRequirementGroups} />
            )}
            <div className="float-end mb-3">
              <button
                type="button"
                disabled={submitting}
                onClick={() =>
                  setFormMode(undefined) && setErrorAlerts(undefined)
                }
                className="cursor-pointer btn btn-light me-3"
              >
                {I18n.t('cancel')}
              </button>
              <button
                type="submit"
                onClick={handleSubmit}
                disabled={invalid || submitting}
                className="cursor-pointer btn btn-primary"
              >
                {I18n.t('save')}
              </button>
            </div>
          </form>
        )}
      />
    </div>
  );
};

ProjectForm.defaultProps = {
  project: {
    id: '',
    name: '',
    instance: [],
    customers: [],
    requirement_groups: [],
    requirement_group_and_project_ids: []
  },
  selectedProjectId: undefined,
  errorAlerts: undefined
};

ProjectForm.propTypes = {
  selectedProjectId: PropTypes.string,
  formMode: PropTypes.string.isRequired,
  project: PropTypes.instanceOf(Object),
  saveProject: PropTypes.func.isRequired,
  setFormMode: PropTypes.func.isRequired,
  errorAlerts: PropTypes.string,
  setErrorAlerts: PropTypes.func.isRequired
};

export default ProjectForm;
