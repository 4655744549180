import React, { useState, useEffect, useCallback } from 'react';

import axios from '../base/axios';

import Users from './users';
import Attachments from './attachments';
import EventTriggers from './eventTriggers';

const filters = [
  { value: 'all', label: I18n.t('archives.all') },
  {
    value: 'event_triggers',
    label: I18n.t('archives.event_triggers.title.other')
  },
  { value: 'users', label: I18n.t('archives.users.title.other') },
  { value: 'documents', label: I18n.t('archives.documents.title.other') }
];

const Archives = () => {
  const [users, setUsers] = useState();
  const [attachments, setAttachments] = useState();
  const [eventTriggers, setEventTriggers] = useState();
  const [filter, setFilter] = useState(filters[0].value);

  const onFilterChange = useCallback((e) => {
    setFilter(e.target.value);
  }, []);

  const showArchives = useCallback(
    (resource) => filter === 'all' || filter === resource,
    [filter]
  );

  const loadArchives = useCallback(() => {
    axios
      .get('/archives', { headers: { accept: 'application/json' } })
      .then(({ data }) => {
        setUsers(data.users);
        setAttachments(data.attachments);
        setEventTriggers(data.event_triggers);
      });
  }, []);

  useEffect(() => {
    if (!(users || attachments || eventTriggers)) loadArchives();
  }, [users, attachments, eventTriggers, loadArchives]);

  if (!(users && attachments && eventTriggers)) return '';

  return (
    <div className="row justify-content-center">
      <div className="col-12 col-xl-10 col-xxl-9">
        <div className="row">
          <div className="col-7">
            <h3>
              <i className="fas fa-archive me-2" />
              <span className="fa5-text">{I18n.t('archives.title')}</span>
            </h3>
          </div>
          <div className="col-5">
            <select
              value={filter}
              className="form-select"
              onChange={onFilterChange}
            >
              {filters.map((obj) => (
                <option key={obj.value} value={obj.value}>
                  {obj.label}
                </option>
              ))}
            </select>
          </div>
        </div>
        {showArchives('users') && (
          <>
            <hr />
            <Users users={users} setUsers={setUsers} />
          </>
        )}
        {showArchives('documents') && (
          <>
            <hr />
            <Attachments
              attachments={attachments}
              setAttachments={setAttachments}
            />
          </>
        )}
        {showArchives('event_triggers') && (
          <>
            <hr />
            <EventTriggers
              eventTriggers={eventTriggers}
              setEventTriggers={setEventTriggers}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default Archives;
