import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';

import Remark from './Remark';
import I18n from '../base/i18n';

const Remarks = ({ remarkableId, remarkableType, userCanModifyRemark, remarks: propRemarks }) => {
  I18n.locale = $('body').data('locale');

  const [removed, setRemoved] = useState(0);
  const [remarks, setRemarks] = useState(propRemarks);

  useEffect(() => {
    setRemarks(propRemarks);
  }, [propRemarks]);

  const remove = () => setRemoved(removed + 1);

  const addRemark = () => setRemarks([...remarks, null]);

  const headingI18nKey = remarkableType === 'CsrNonConformityTask' ? 'csr_non_conformity_tasks.remark_heading' : 'csr_non_conformity_tasks.remark';

  return (
    <>
      <h5>
        <i className="far fa-comment-alt fa-xs me-2" />
        {I18n.t(headingI18nKey)}
        {userCanModifyRemark && (
          <button
            type="button"
            onClick={addRemark}
            data-tippy-placement="right"
            className="btn btn-secondary btn-xs ms-2"
            data-tippy-content={I18n.t('assessments.questions.details.add_remark')}
          >
            <i className="fas fa-plus" />
          </button>
        )}
      </h5>

      <div className="ms-4">
        {remarks.length - removed === 0 && (
          <p className="assessmentAnswer-auditReasonHintAlert">
            {remarkableType === 'LopTask'
              ? I18n.t('lop_tasks.no_remark')
              : I18n.t('assessments.questions.details.no_remark')}
          </p>
        )}

        {remarks.map((remark, index) => (
          <Remark
            remark={remark}
            remove={remove}
            key={`remark_${index}`}
            remarkableId={remarkableId}
            remarkableType={remarkableType}
            userCanModifyRemark={userCanModifyRemark}
            lastRemarkOfRemarkable={index === remarks.length - 1}
          />
        ))}
      </div>
    </>
  );
};

Remarks.defaultProps = {
  remarks: []
};

Remarks.propTypes = {
  remarkableId: PropTypes.string.isRequired,
  remarkableType: PropTypes.string.isRequired,
  remarks: PropTypes.arrayOf(PropTypes.object),
  userCanModifyRemark: PropTypes.bool.isRequired
};

export default Remarks;
