import PropTypes from 'prop-types';
import React, { useCallback } from 'react';

import EventTrigger from './eventTrigger';

const EventTriggers = ({ eventTriggers, setEventTriggers }) => {
  const removeEventTrigger = useCallback(
    (index) => {
      setEventTriggers([
        ...eventTriggers.slice(0, index),
        ...eventTriggers.slice(index + 1)
      ]);
    },
    [eventTriggers, setEventTriggers]
  );

  return (
    <div className="card">
      <div className="card-body">
        <h5>
          <span className="fa5-text">
            {I18n.t('archives.event_triggers.title.other')}
          </span>
        </h5>
        {eventTriggers.length ? (
          <table className="table table-hover align-middle">
            <thead>
              <tr>
                <th>{I18n.t('archives.event_triggers.title.one')}</th>
                <th>{I18n.t('archives.event_triggers.event_trigger_type')}</th>
                <th aria-label="Actions" />
              </tr>
            </thead>
            <tbody>
              {eventTriggers.map((eventTrigger, index) => (
                <EventTrigger
                  index={index}
                  key={eventTrigger.id}
                  eventTrigger={eventTrigger}
                  removeEventTrigger={removeEventTrigger}
                />
              ))}
            </tbody>
          </table>
        ) : (
          <div className="text-center">
            {I18n.t('archives.event_triggers.no_archives')}
          </div>
        )}
      </div>
    </div>
  );
};

EventTriggers.propTypes = {
  setEventTriggers: PropTypes.func.isRequired,
  eventTriggers: PropTypes.instanceOf(Object).isRequired
};

export default EventTriggers;
