import axios from 'axios';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

const ShowCustomers = ({ requirementGroups }) => {
  const [allCustomers, setAllCustomers] = useState();

  useEffect(() => {
    axios
      .get('/requirement_groups/customers', {
        headers: { accept: 'application/json' },
        params: {
          requirement_group_ids: requirementGroups.map(
            (requirementGroup) => requirementGroup.value
          )
        }
      })
      .then(({ data }) => setAllCustomers(data));
  }, [requirementGroups]);

  return (
    <table className="table table-borderless text-secondary align-middle">
      <thead>
        <tr className="d-flex">
          <th className="col-4">
            {I18n.t('activerecord.models.requirement_group.one')}
          </th>
          <th className="col-8">
            {I18n.t('activerecord.models.customer.other')}
          </th>
        </tr>
      </thead>
      <tbody>
        {requirementGroups.map((requirementGroup, index) => (
          <tr className="d-flex" key={`requirement_group_customer_${index}`}>
            <td className="col-4">{requirementGroup.label}</td>
            <td className="col-8">
              {allCustomers &&
                allCustomers.find(
                  (customer) => customer[requirementGroup.value]
                ) &&
                allCustomers
                  .find((customer) => customer[requirementGroup.value])
                  [requirementGroup.value].join(', ')}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

ShowCustomers.defaultProps = {
  requirementGroups: []
};

ShowCustomers.propTypes = {
  requirementGroups: PropTypes.arrayOf(PropTypes.object)
};

export default ShowCustomers;
