import React from 'react';
import Items from './Items';
import axios from '../base/axios';
import ErrorBoundary from '../base/error_boundary';

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      process_groups: []
    };
  }

  componentDidMount() {
    const { id } = this.props;
    this.loadItems(id);
    this.loadProcessGroups();
  }

  loadItems(id) {
    axios.get(`/key_elements/${id}.json`).then((response) => {
      this.setState({ items: response.data.data });
    });
  }

  loadProcessGroups() {
    axios.get('/process_groups.json').then((response) => {
      const process_groups = response.data.sort((a, b) => a.id - b.id);
      const temp = [];
      process_groups.forEach((pg) => {
        pg.id = parseInt(pg.id);
        pg.level = 0;
        pg.children = [];
        temp[pg.id] = pg;
        if (pg.parent_id) {
          const parent = temp[pg.parent_id];
          pg.level = parent.level + 1;
          parent.children.push(pg);
        }
      });
      this.setState({ process_groups });
    });
  }

  render() {
    const { id } = this.props;
    const { items, process_groups } = this.state;

    return (
      <ErrorBoundary>
        {[...Array(6)].map((_, i) => (
          <Items
            key={i}
            item_type={i}
            key_element_id={id}
            process_groups={process_groups}
            items={items.filter((item) => item.item_type == i)}
          />
        ))}
      </ErrorBoundary>
    );
  }
}

export default Home;
