import React from 'react';
import PropTypes from 'prop-types';

const doNothing = () => {};

const ParentsToolbar = ({
  edit,
  create,
  remove,
  nextLevel,
  previousLevel,
  loadingRecords,
  loadingSubRecords,
  recordType
}) => (
  <div className="col">
    <div
      aria-hidden="true"
      onClick={() => create(false)}
      className="btn btn-secondary btn-sm d-block mb-2"
      data-tippy-content={I18n.t(`${recordType}_selector.new`)}
    >
      <i className="fas fa-plus" />
    </div>
    <div
      aria-hidden="true"
      onClick={loadingSubRecords ? doNothing : edit}
      className="btn btn-secondary btn-sm d-block mb-2"
      data-tippy-content={I18n.t(`${recordType}_selector.edit`)}
    >
      <i className="fas fa-pen" />
    </div>
    <div
      onClick={remove}
      aria-hidden="true"
      className="btn btn-secondary btn-sm d-block mb-2"
      data-tippy-content={I18n.t(`${recordType}_selector.delete`)}
    >
      <i className="fas fa-trash" />
    </div>
    <div
      aria-hidden="true"
      className="btn btn-secondary btn-sm d-block mb-2"
      onClick={loadingRecords ? doNothing : previousLevel}
      data-tippy-content={I18n.t(`${recordType}_selector.parent`)}
    >
      <i className="fas fa-arrow-up" />
    </div>
    <div
      aria-hidden="true"
      className="btn btn-secondary btn-sm d-block mb-2"
      onClick={loadingSubRecords ? doNothing : nextLevel}
      data-tippy-content={I18n.t(`${recordType}_selector.children`)}
    >
      <i className="fas fa-arrow-down" />
    </div>
  </div>
);

ParentsToolbar.propTypes = {
  edit: PropTypes.func.isRequired,
  create: PropTypes.func.isRequired,
  remove: PropTypes.func.isRequired,
  nextLevel: PropTypes.func.isRequired,
  previousLevel: PropTypes.func.isRequired,
  loadingRecords: PropTypes.bool.isRequired,
  loadingSubRecords: PropTypes.bool.isRequired,
  recordType: PropTypes.string.isRequired
};

export default ParentsToolbar;
