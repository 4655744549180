import React, { useState, useEffect } from 'react';
import I18n from '../base/i18n';
import Item from './Item';

export const DOCUMENT = 0;
export const WEBSITE = 1;
export const ITTOOL = 2;
export const RESPONSIBILITY = 3;
export const KPI = 4;
export const INTERFACE = 5;

const Items = (props) => {
  I18n.locale = $('body').data('locale');

  const LABELS = [
    I18n.t('key_elements.items.Documents'),
    I18n.t('key_elements.items.Websites'),
    I18n.t('key_elements.items.IT-Tools'),
    I18n.t('key_elements.items.General Responsibility'),
    I18n.t("key_elements.items.KPI's"),
    I18n.t('key_elements.items.Interfaces')
  ];

  const { item_type, key_element_id, process_groups } = props;
  const [items, setItems] = useState(props.items);
  const [new_items, setNewItems] = useState([]);
  const [removed, setRemoved] = useState(0);

  useEffect(() => {
    setItems(props.items);
  }, [props.items]);

  const addItem = () => {
    setNewItems(new Array(new_items.length + 1).fill(null));
  };
  const remove = () => {
    setRemoved(removed + 1);
  };

  const titles = null;

  return (
    <div className="row m-2">
      <button className="btn btn-primary col-12 mb-3" onClick={addItem}>
        <span className="float-start">
          <i className="fas fa-plus-square me-2" />
          {LABELS[item_type]}
        </span>
      </button>

      {titles}

      {items.map((item, i) => (
        <Item
          key={i}
          index={i}
          item={item}
          remove={remove}
          key_element_id={key_element_id}
          item_type={item_type}
          process_groups={process_groups}
        />
      ))}
      {new_items.map((item, i) => (
        <Item
          key={`new${i}`}
          index={`new${i}`}
          item={item}
          remove={remove}
          key_element_id={key_element_id}
          item_type={item_type}
          process_groups={process_groups}
        />
      ))}
    </div>
  );
};

export default Items;
