import React from 'react';
import PropTypes from 'prop-types';

import I18n from '../base/i18n';

const NormPicker = ({ norms, onNormChange }) => {
  I18n.locale = $('body').data('locale');

  return (
    <>
      <div className="d-inline">
        <label className="me-2" htmlFor="norm-selector">
          {`${I18n.t('activerecord.models.norm.one')}:`}
        </label>

        <select name="norm-selector" onChange={onNormChange}>
          <option value="">
            {' '}
            {`--- ${I18n.t('norms.modal.select_norm')} ---`}{' '}
          </option>
          {norms.map((norm) => (
            <option key={norm.id} value={norm.id}>
              {norm.name}
            </option>
          ))}
        </select>
      </div>
    </>
  );
};

NormPicker.propTypes = {
  onNormChange: PropTypes.func.isRequired,
  norms: PropTypes.arrayOf(PropTypes.object).isRequired
};
export default NormPicker;
