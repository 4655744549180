import React from 'react';
import PropTypes from 'prop-types';
import I18n from '../base/i18n';

const CommentBadge = ({ commentType }) => (
  <>
    {commentType === 'decline' && (
      <span
        className="badge comment-badge red-badge rounded-pill me-2"
        data-tippy-content={I18n.t('lop_tasks.comment_type_decline')}
      >
        <i className="fa fa-ban" />
      </span>
    )}
    {commentType === 'delegate' && (
      <span
        className="badge comment-badge blue-badge rounded-pill me-2"
        data-tippy-content={I18n.t('lop_tasks.comment_type_delegate')}
      >
        <i className="fa fa-user-friends" />
      </span>
    )}
    {commentType === 'accepted' && (
      <span
        className="badge comment-badge green-badge rounded-pill me-2"
        data-tippy-content={I18n.t('lop_tasks.comment_type_accepted')}
      >
        <i className="fa fa-check" />
      </span>
    )}
    {commentType === 'rejected' && (
      <span
        className="badge comment-badge red-badge rounded-pill me-2"
        data-tippy-content={I18n.t('lop_tasks.comment_type_rejected')}
      >
        <i className="fa fa-times" />
      </span>
    )}
  </>
);

CommentBadge.propTypes = {
  commentType: PropTypes.string.isRequired
};

export default CommentBadge;
