import PropTypes from 'prop-types';
import React, { useCallback } from 'react';

import Attachment from './attachment';

const Attachments = ({ attachments, setAttachments }) => {
  const removeAttachment = useCallback(
    (index) => {
      setAttachments([
        ...attachments.slice(0, index),
        ...attachments.slice(index + 1)
      ]);
    },
    [attachments, setAttachments]
  );

  return (
    <div className="card">
      <div className="card-body">
        <h5>
          <span className="fa5-text">
            {I18n.t('archives.documents.title.other')}
          </span>
        </h5>
        {attachments.length ? (
          <table className="table table-hover align-middle">
            <thead>
              <tr>
                <th>{I18n.t('archives.documents.title.one')}</th>
                <th>ID</th>
                <th>{I18n.t('archives.documents.title.instance')}</th>
                <th aria-label="Actions" />
              </tr>
            </thead>
            <tbody>
              {attachments.map((attachment, index) => (
                <Attachment
                  index={index}
                  key={attachment.id}
                  attachment={attachment}
                  removeAttachment={removeAttachment}
                />
              ))}
            </tbody>
          </table>
        ) : (
          <div className="text-center">
            {I18n.t('archives.documents.no_archives')}
          </div>
        )}
      </div>
    </div>
  );
};

Attachments.propTypes = {
  setAttachments: PropTypes.func.isRequired,
  attachments: PropTypes.instanceOf(Object).isRequired
};

export default Attachments;
