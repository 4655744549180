import React from 'react';
import PropTypes from 'prop-types';
import I18n from '../base/i18n';

const CsrEmailModal = ({ generateEmail }) => (
  <>
    <div
      className="modal fade"
      id="csrEmailModal"
      tabIndex="-1"
      aria-labelledby="csrEmailModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="csrEmailModalLabel">
              {I18n.t('csr.generate_email')}
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            />
          </div>
          <div className="modal-body">{I18n.t('csr.generate_email_info')}</div>

          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-primary"
              onClick={generateEmail}
              data-bs-dismiss="modal"
            >
              {I18n.t('csr.send_email')}
            </button>
          </div>
        </div>
      </div>
    </div>
  </>
);

CsrEmailModal.propTypes = {
  generateEmail: PropTypes.instanceOf(Object).isRequired
};

export default CsrEmailModal;
