import React from 'react';
import { render } from 'react-dom';
import ReactOnRails from 'react-on-rails';

import Archives from '../components/archives';
import Projects from '../components/projects';
import Customers from '../components/customers';
import RemarkHome from '../components/remarks/Home';
import ReasonHome from '../components/reasons/Home';
import Attachments from '../components/attachments';
import KeyElement from '../components/key_elements/Home';
import ChapterPicker from '../components/chapter_picker/Home';

// This is how react_on_rails can see the components in the browser.
ReactOnRails.register({
  KeyElement,
  ChapterPicker
});

// Below are dynamically added by jquery and are not rendered by ReactOnRails
// they need to be prerendered for ReactOnRails to render them properly
$(document).on('turbolinks:load', () => {
  for (const react_remarks of document.getElementsByClassName(
    'react-remarks'
  )) {
    // Check that no children are present before adding a new remark,
    // to prevent duplication of the same remark because of turbolinks
    if (react_remarks.children.length === 0) {
      render(
        <RemarkHome
          userCanModifyRemark={react_remarks.hasAttribute(
            'data-user-can-modify-remark'
          )}
          remarkableId={react_remarks.getAttribute('data-remarkable-id')}
          remarkableType={react_remarks.getAttribute('data-remarkable-type')}
        />,
        react_remarks.appendChild(document.createElement('div'))
      );
    }
  }

  for (const react_attachments of document.getElementsByClassName(
    'react-attachments'
  )) {
    // Check that no children are present before adding a new attachment,
    // to prevent duplication of the same attachment because of turbolinks
    if (react_attachments.children.length === 0) {
      render(
        <Attachments
          noAttachmentText={react_attachments.getAttribute(
            'data-no-attachment-text'
          )}
          acceptedMimeTypes={react_attachments.getAttribute(
            'data-accepted-mime-types'
          )}
          objectId={react_attachments.getAttribute('data-object-id')}
          objectType={react_attachments.getAttribute('data-object-type')}
          userMayAssess={react_attachments.hasAttribute('data-user-may-assess')}
        />,
        react_attachments.appendChild(document.createElement('div'))
      );
    }
  }

  for (const react_reasons of document.getElementsByClassName(
    'react-reasons'
  )) {
    // Check that no children are present before adding a new reason,
    // to prevent duplication of the same reason because of turbolinks
    if (react_reasons.children.length === 0) {
      render(
        <ReasonHome
          reasonableId={react_reasons.getAttribute('data-reasonable-id')}
          reasonableType={react_reasons.getAttribute('data-reasonable-type')}
          userCanModifyReason={react_reasons.hasAttribute(
            'data-user-can-modify-reason'
          )}
        />,
        react_reasons.appendChild(document.createElement('div'))
      );
    }
  }

  for (const react_archives of document.getElementsByClassName(
    'react-archives'
  )) {
    render(
      <Archives />,
      react_archives.appendChild(document.createElement('div'))
    );
  }

  for (const react_projects of document.getElementsByClassName(
    'react-projects'
  )) {
    // Check that no children are present before adding a new project,
    // to prevent duplication of the same project because of turbolinks
    if (react_projects.children.length === 0) {
      render(
        <Projects />,
        react_projects.appendChild(document.createElement('div'))
      );
    }
  }

  for (const react_customers of document.getElementsByClassName(
    'react-customers'
  )) {
    // Check that no children are present before adding a new customer,
    // to prevent duplication of the same customer because of turbolinks
    if (react_customers.children.length === 0) {
      render(
        <Customers />,
        react_customers.appendChild(document.createElement('div'))
      );
    }
  }
});
