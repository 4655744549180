import React from 'react';
import PropTypes from 'prop-types';

const ChildSelector = ({ records, getLabel, onSelect, disabled }) => (
  <div className="col-6">
    <select
      multiple
      onChange={onSelect}
      disabled={disabled}
      className="form-control h-100"
    >
      {Object.keys(records).map((key) => (
        <option key={key} value={key}>
          {getLabel(records[key])}
        </option>
      ))}
    </select>
  </div>
);

ChildSelector.propTypes = {
  onSelect: PropTypes.func.isRequired,
  getLabel: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  records: PropTypes.instanceOf(Object).isRequired
};

export default ChildSelector;
