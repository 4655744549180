import axios from 'axios';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';
import React, { useState, useEffect, useCallback } from 'react';

import AsyncSelectInput from '../commons/formFields/asyncSelectInput';
import CsrEmailModal from '../commons/csr_email_modal';

const SearchRequirementGroups = ({
  meta,
  multi,
  input,
  onChange,
  clearable,
  className,
  searchable,
  placeholder,
  cacheOptions,
  csrType
}) => {
  const [defaultOptions, setDefaultOptions] = useState();
  const [searchQuery, setSearchQuery] = useState();
  const [emailSent, setEmailSent] = useState();

  const search = useCallback((query, callback) => {
    axios
      .get(`/requirement_groups?query=${query}`, {
        headers: { accept: 'application/json' }
      })
      .then(({ data }) => {
        const options = data.map((requirementGroup) => ({
          value: requirementGroup.id,
          label: requirementGroup.name
        }));
        callback(options);
      });
  }, []);

  useEffect(() => {
    if (!defaultOptions) search('', setDefaultOptions);
  }, [defaultOptions]);

  let handleRequirementGroupSearch = (value, callback) => {
    const query = value.trim();
    if (!query.length) {
      callback([]);
      return;
    }
    setSearchQuery(query);
    search(query, callback);
  };

  handleRequirementGroupSearch = debounce(handleRequirementGroupSearch, 300);

  const generateEmail = () => {
    axios
      .post(`/${csrType}/request_serviced_csr`, {
        query: searchQuery
      })
      .then(() => {
        setEmailSent(I18n.t('csr.email_sent'));
      });
  };

  return (
    <>
      <AsyncSelectInput
        meta={meta}
        multi={multi}
        input={input}
        onChange={onChange}
        className={className}
        clearable={clearable}
        searchable={searchable}
        placeholder={placeholder}
        cacheOptions={cacheOptions}
        defaultOptions={defaultOptions}
        loadOptions={handleRequirementGroupSearch}
      />

      {emailSent && <div className="text-success mt-1">{emailSent}</div>}

      <CsrEmailModal generateEmail={generateEmail} />
    </>
  );
};

SearchRequirementGroups.defaultProps = {
  multi: false,
  meta: undefined,
  clearable: false,
  input: undefined,
  searchable: false,
  cacheOptions: false,
  onChange: undefined,
  className: undefined,
  placeholder: undefined,
  csrType: undefined
};

SearchRequirementGroups.propTypes = {
  multi: PropTypes.bool,
  onChange: PropTypes.func,
  clearable: PropTypes.bool,
  searchable: PropTypes.bool,
  className: PropTypes.string,
  cacheOptions: PropTypes.bool,
  placeholder: PropTypes.string,
  input: PropTypes.instanceOf(Object),
  meta: PropTypes.objectOf(PropTypes.any),
  csrType: PropTypes.string
};

export default SearchRequirementGroups;
