import React from 'react';
import PropTypes from 'prop-types';

const Footer = ({ onClick }) => (
  <div className="modal-footer">
    <button className="btn btn-secondary" type="button" data-bs-dismiss="modal">
      {I18n.t('cancel')}
    </button>

    <button
      id="add-chapters"
      className="btn btn-primary"
      type="button"
      data-bs-dismiss="modal"
      onClick={onClick}
    >
      {I18n.t('save')}
    </button>
  </div>
);

Footer.propTypes = {
  onClick: PropTypes.func.isRequired
};

export default Footer;
