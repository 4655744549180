import axios from 'axios';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';

const ShowProjects = ({ requirementGroups }) => {
  const [allProjects, setAllProjects] = useState();

  useEffect(() => {
    axios
      .get('/requirement_groups/projects', {
        headers: { accept: 'application/json' },
        params: {
          requirement_group_ids: requirementGroups.map(
            (requirementGroup) => requirementGroup.value
          )
        }
      })
      .then(({ data }) => setAllProjects(data));
  }, [requirementGroups]);

  return (
    <table className="table table-borderless text-secondary align-middle caption-top">
      <caption>{I18n.t('csr.duplicate_answers')}</caption>
      <thead>
        <tr className="d-flex">
          <th className="col-4">
            {I18n.t('activerecord.models.requirement_group.one')}
          </th>
          <th className="col-8">
            {I18n.t('activerecord.models.project.other')}
          </th>
        </tr>
      </thead>
      <tbody>
        {requirementGroups.map((requirementGroup, index) => (
          <tr className="d-flex" key={`requirement_group_project_${index}`}>
            <td className="col-4">{requirementGroup.label}</td>
            <td className="col-8">
              <Field
                name={`requirement_group_and_project_ids.${requirementGroup.value}`}
                component="select"
                className="form-select"
              >
                <option>&nbsp;</option>
                {allProjects &&
                  allProjects.find(
                    (project) => project[requirementGroup.value]
                  ) &&
                  allProjects
                    .find((project) => project[requirementGroup.value])
                    [requirementGroup.value].map((projectIdAndName) => (
                      <option
                        value={projectIdAndName[0]}
                        key={`requirement_group_${requirementGroup.value}_and_project_${projectIdAndName[0]}`}
                      >
                        {projectIdAndName[1]}
                      </option>
                    ))}
              </Field>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

ShowProjects.defaultProps = {
  requirementGroups: []
};

ShowProjects.propTypes = {
  requirementGroups: PropTypes.arrayOf(PropTypes.object)
};

export default ShowProjects;
