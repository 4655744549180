import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';

import Reasons from './Reasons';
import axios from '../base/axios';
import ErrorBoundary from '../base/error_boundary';

const Home = ({ reasonableId, reasonableType, userCanModifyReason }) => {
  const [answer, setAnswer] = useState({});
  const [reasons, setReasons] = useState([]);

  const loadReasons = () => {
    const url = `/reasons?reasonable_id=${reasonableId}&reasonable_type=${reasonableType}`;
    axios.get(url).then(({ data }) => {
      setAnswer(data);
      setReasons(data.reasons);
    });
  };

  useEffect(() => {
    loadReasons();
  }, []);

  return (
    <ErrorBoundary>
      <Reasons
        answer={answer}
        reasons={reasons}
        reasonableId={reasonableId}
        reasonableType={reasonableType}
        userCanModifyReason={userCanModifyReason}
      />
    </ErrorBoundary>
  );
};

Home.propTypes = {
  reasonableId: PropTypes.string.isRequired,
  reasonableType: PropTypes.string.isRequired,
  userCanModifyReason: PropTypes.bool.isRequired
};

export default Home;
