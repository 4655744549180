import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

const Customer = ({ customer }) => {
  const renderSubCustomers = useCallback(() => (
    <div className="col">
      <div className="children card h-100">
        <div className="card-header">{I18n.t('customers.subcustomers')}</div>
        <div className="card-body">
          <ul>
            {customer.sub_customers.map((subCustomer) => (
              <li key={subCustomer.id}>
                <a href={`/customers/?id=${subCustomer.id}`}>
                  {subCustomer.name}
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  ));

  const renderRequirementGroups = useCallback(() => (
    <div className="col">
      <div className="children card h-100">
        <div className="card-header">
          {I18n.t('activerecord.models.requirement_group.other')}
        </div>
        <div className="card-body">
          <ul>
            {customer.requirement_groups.map((requirementGroup) => (
              <li key={requirementGroup.id}>
                <a href={`/requirement_groups/${requirementGroup.id}`}>
                  {requirementGroup.name}
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  ));

  const renderProjects = useCallback(() => (
    <div className="col">
      <div className="children card h-100">
        <div className="card-header">
          {I18n.t('activerecord.models.project.other')}
        </div>
        <div className="card-body">
          <ul>
            {customer.projects.map(
              (project) =>
                project.parent_id === null && (
                  <li key={project.id}>
                    <a href={`/projects/?id=${project.id}`}>{project.name}</a>
                  </li>
                )
            )}
          </ul>
        </div>
      </div>
    </div>
  ));

  const renderInstances = useCallback(() => (
    <div className="col">
      <div className="children card h-100">
        <div className="card-header">
          {I18n.t('activerecord.models.instance.other')}
        </div>
        <div className="card-body">
          <ul>
            {customer.instances.map((instance) => (
              <li key={instance.id}>
                <a href={`/instances/${instance.id}`}>{instance.name}</a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  ));

  return (
    <div>
      <h4 className="mb-3">{customer.name}</h4>
      <div className="row row-cols-1 row-cols-md-2 g-4">
        {renderSubCustomers()}
        {renderRequirementGroups()}
        {renderInstances()}
        {renderProjects()}
      </div>
    </div>
  );
};

Customer.propTypes = {
  customer: PropTypes.instanceOf(Object).isRequired
};

export default Customer;
