import React from 'react';
import PropTypes from 'prop-types';
import { ReactTrixRTEInput, ReactTrixRTEToolbar } from 'react-trix-rte';

const TextArea = ({ text, noText, setText }) => (
  <>
    <ReactTrixRTEToolbar
      toolbarId="react-trix-rte-editor"
      toolbarActions={[
        'bold',
        'italic',
        'strike',
        'link',
        'heading1',
        'quote',
        'code',
        'bullet',
        'number',
        'outdent',
        'indent',
        // 'attachFiles',
        'undo',
        'redo'
      ]}
    />
    <ReactTrixRTEInput
      toolbarId="react-trix-rte-editor"
      defaultValue={text}
      onChange={(_, newText) => setText(newText)}
      placeholder={I18n.t('remark_placeholder')}
      className={noText ? 'form-control is-invalid' : 'form-control'}
    />
  </>
);

TextArea.propTypes = {
  text: PropTypes.string.isRequired,
  noText: PropTypes.bool.isRequired,
  setText: PropTypes.func.isRequired
};

export default TextArea;
