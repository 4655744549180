import PropTypes from 'prop-types';
import React, { useState, useEffect, useCallback } from 'react';

import Remarks from './Remarks';
import axios from '../base/axios';
import ErrorBoundary from '../base/error_boundary';

const Home = ({ remarkableId, remarkableType, userCanModifyRemark }) => {
  const [remarks, setRemarks] = useState();

  const loadRemarks = useCallback(() => {
    axios
      .get(
        `/remarks?remarkable_id=${remarkableId}&remarkable_type=${remarkableType}`
      )
      .then(({ data }) => setRemarks(data));
  }, []);

  useEffect(() => {
    if (!remarks) loadRemarks();
  }, [remarks, loadRemarks]);

  return (
    <ErrorBoundary>
      <Remarks
        remarks={remarks}
        remarkableId={remarkableId}
        remarkableType={remarkableType}
        userCanModifyRemark={userCanModifyRemark}
      />
    </ErrorBoundary>
  );
};

Home.propTypes = {
  remarkableId: PropTypes.string.isRequired,
  remarkableType: PropTypes.string.isRequired,
  userCanModifyRemark: PropTypes.bool.isRequired
};

export default Home;
