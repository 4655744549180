import PropTypes from 'prop-types';
import React, { useState, useCallback } from 'react';

import axios from '../base/axios';

const Attachments = ({ idx, attachment, userMayAssess, removeAttachment }) => {
  const [archiving, setArchiving] = useState(false);

  const archiveAttachment = useCallback(() => {
    if (confirm(I18n.t('are_you_sure'))) {
      setArchiving(true);
      axios
        .put(`/attachments/${attachment.id}/toggle_archived`, {
          archived: true
        })
        .then(() => removeAttachment(idx))
        .catch(() => setArchiving(false));
    }
  }, [idx, attachment, removeAttachment]);

  return (
    <li className="mb-2">
      <a target="_blank" href={attachment.url} rel="noreferrer">
        <i className="far fa-file-image me-1 fa-sm" />
        <span className="fa5-text">{attachment.file_file_name}</span>
      </a>
      {userMayAssess && !archiving && (
        <small aria-hidden="true" onClick={archiveAttachment}>
          <span
            data-tippy-placement="right"
            className="ms-3 text-secondary"
            data-tippy-content={I18n.t('archive')}
          >
            <i className="fas fa-archive" />
          </span>
        </small>
      )}
    </li>
  );
};

Attachments.propTypes = {
  idx: PropTypes.number.isRequired,
  userMayAssess: PropTypes.bool.isRequired,
  removeAttachment: PropTypes.func.isRequired,
  attachment: PropTypes.instanceOf(Object).isRequired
};

export default Attachments;
