import axios from 'axios';
import React, { useState, useCallback, useEffect } from 'react';

import Project from './project';
import ProjectForm from './form';
import ModelHierarchy from '../commons/modelHierarchy';
import SuccessAlert from '../commons/alerts/success_alert';

const Projects = () => {
  I18n.locale = $('body').data('locale');
  const [projects, setProjects] = useState();
  const [formMode, setFormMode] = useState();
  const [subProjects, setSubProjects] = useState();
  const [submitting, setSubmitting] = useState(false);
  const [selectedProject, setSelectedProject] = useState();
  const [loadingProject, setLoadingProject] = useState(false);
  const [selectedProjectId, setSelectedProjectId] = useState();
  const [loadingProjects, setLoadingProjects] = useState(false);
  const [errorAlerts, setErrorAlerts] = useState();
  const [successAlerts, setSuccessAlerts] = useState();

  const objectifyProjects = useCallback((records) => {
    const obj = {};
    records.forEach((record) => {
      obj[record.id] = record;
    });
    return obj;
  }, []);

  const loadProjects = useCallback(
    (id = new URLSearchParams(window.location.search).get('id')) => {
      setLoadingProjects(true);
      axios
        .get(`/projects${id ? `?id=${id}` : ''}`, {
          headers: { accept: 'application/json' }
        })
        .then(({ data }) => {
          setProjects(objectifyProjects(data));
          if (id) setSelectedProjectId(`${id}`);
        })
        .finally(() => setLoadingProjects(false));
    },
    [objectifyProjects]
  );

  const loadProject = useCallback(
    (id) => {
      setLoadingProject(true);
      axios
        .get(`/projects/${id}`)
        .then(({ data }) => {
          setSubProjects(objectifyProjects(data.sub_projects));
          setSelectedProject(data);
        })
        .finally(() => setLoadingProject(false));
    },
    [objectifyProjects]
  );

  const deleteProject = useCallback(() => {
    axios.delete(`/projects/${selectedProjectId}`).then(() => {
      const obj = { ...projects };
      delete obj[selectedProjectId];
      setProjects(obj);
      setSelectedProjectId(Object.keys(obj)[0]);
      setSuccessAlerts(I18n.t('projects.deleted'));
    });
  }, [projects, selectedProjectId]);

  const saveExistingProject = useCallback(
    (values) => {
      setSubmitting(true);
      return axios
        .put(`/projects/${values.id}`, values)
        .then(({ data }) => {
          setProjects({ ...projects, [data.id]: data });
          setSelectedProject({ ...selectedProject, ...data });
          setSubProjects(objectifyProjects(data.sub_projects));
          setFormMode(undefined);
          setErrorAlerts(undefined);
        })
        .catch((errors) => {
          setErrorAlerts(errors.response.data.error);
        })
        .finally(() => {
          setSubmitting(false);
          setSuccessAlerts(I18n.t('projects.updated'));
        });
    },
    [projects]
  );

  const saveNewProject = useCallback(
    (values) => {
      setSubmitting(true);
      return axios
        .post('/projects/', values)
        .then(({ data }) => {
          if (data.parent_id) {
            setSelectedProject({
              ...selectedProject,
              sub_projects: [...selectedProject.sub_projects, data]
            });
            setSubProjects(objectifyProjects(selectedProject.sub_projects));
          } else {
            setProjects({ ...projects, [data.id]: data });
          }
          setFormMode(undefined);
          setErrorAlerts(undefined);
        })
        .catch((errors) => {
          setErrorAlerts(errors.response.data.error);
        })
        .finally(() => {
          setSubmitting(false);
          setSuccessAlerts(I18n.t('projects.created'));
        });
    },
    [projects, subProjects, selectedProject]
  );

  useEffect(() => {
    if (!projects) loadProjects();
  }, [projects, loadProjects]);

  useEffect(() => {
    if (selectedProjectId) {
      setFormMode(undefined);
      setErrorAlerts(undefined);
      loadProject(selectedProjectId);
    }
  }, [loadProject, selectedProjectId]);

  const onParentSelect = useCallback(
    (e) => {
      if (!submitting && selectedProjectId !== e.target.value) {
        setSubProjects({});
        setFormMode(undefined);
        setErrorAlerts(undefined);
        setSuccessAlerts(undefined);
        setSelectedProjectId(e.target.value);
      }
    },
    [submitting, loadingProject, selectedProjectId]
  );

  const onChildSelect = useCallback(
    (e) => {
      if (!submitting) {
        setFormMode(undefined);
        setErrorAlerts(undefined);
        setSuccessAlerts(undefined);
        setProjects(subProjects);
        setSubProjects({});
        setSelectedProjectId(
          e?.target?.value || `${Object.keys(subProjects)[0]}`
        );
      }
    },
    [subProjects, submitting, loadingProjects]
  );

  const nextLevel = useCallback(() => {
    if (!submitting && Object.keys(subProjects || {}).length) {
      onChildSelect();
    }
  }, [subProjects, submitting, onChildSelect]);

  const previousLevel = useCallback(() => {
    if (!submitting && selectedProjectId) {
      const project = projects[selectedProjectId];
      if (project.parent_id) {
        setProjects({});
        setSubProjects(projects);
        loadProjects(project.parent_id);
      }
    }
  }, [projects, loadProjects, submitting, selectedProjectId]);

  const create = useCallback(
    (asChild = false) => {
      setErrorAlerts(undefined);
      setSuccessAlerts(undefined);
      if (!formMode) setFormMode(asChild ? 'createChild' : 'create');
    },
    [formMode]
  );

  const remove = useCallback(() => {
    if (
      !submitting &&
      selectedProjectId &&
      confirm(I18n.t('project_selector.confirm_delete'))
    )
      deleteProject();
  }, [selectedProjectId, deleteProject, submitting]);

  const edit = useCallback(() => {
    if (typeof selectedProject.parent_id === 'number' && !formMode) {
      setFormMode('editChild');
    } else if (selectedProject && !formMode) setFormMode('edit');
  }, [selectedProject, formMode]);

  const saveProject = useCallback(
    (values) =>
      values.id ? saveExistingProject(values) : saveNewProject(values),
    [saveExistingProject, saveNewProject]
  );

  const getLabel = useCallback((record) => record.name, []);

  return (
    <div>
      {successAlerts && <SuccessAlert successAlerts={successAlerts} />}
      <div className="card mb-5">
        <div className="card-body">
          <h1 className="mb-5">
            <i className="fas fa-project-diagram me-2 fa-sm" />
            <span className="fa5-text">
              {I18n.t('activerecord.models.project.other')}
            </span>
          </h1>
          <div className="mb-5">
            <div className="row justify-content-center">
              <div className="col-10">
                <div className="row">
                  <div className="col-6">
                    <h5>
                      {I18n.t('activerecord.models.project.other')}
                      {loadingProjects && (
                        <>
                          {' '}
                          <i className="fas fa-spinner fa-spin me-2 fa-sm" />
                        </>
                      )}
                    </h5>
                  </div>
                  <div className="col-6">
                    <h5>
                      {I18n.t('projects.subprojects')}
                      {loadingProject && (
                        <>
                          {' '}
                          <i className="fas fa-spinner fa-spin me-2 fa-sm" />
                        </>
                      )}
                    </h5>
                  </div>
                </div>
              </div>
            </div>
            <ModelHierarchy
              edit={edit}
              create={create}
              remove={remove}
              records={projects}
              getLabel={getLabel}
              nextLevel={nextLevel}
              subRecords={subProjects}
              previousLevel={previousLevel}
              onChildSelect={onChildSelect}
              onParentSelect={onParentSelect}
              loadingRecords={loadingProjects}
              loadingSubRecords={loadingProject}
              selectedRecord={selectedProjectId}
              recordType="project"
            />
          </div>
          {formMode ? (
            <ProjectForm
              formMode={formMode}
              saveProject={saveProject}
              project={selectedProject}
              setFormMode={setFormMode}
              selectedProjectId={selectedProjectId}
              errorAlerts={errorAlerts}
              setErrorAlerts={setErrorAlerts}
            />
          ) : (
            ''
          )}
          {!formMode && selectedProject ? (
            <Project project={selectedProject} />
          ) : (
            ''
          )}
        </div>
      </div>
    </div>
  );
};

export default Projects;
