import React from 'react';
import PropTypes from 'prop-types';

import Chapter from './Chapter';

const Chapters = ({ chapters, onCheckboxChange, chapterIsSelected }) => {
  const renderChapters = () =>
    chapters.map((chapter) => (
      <Chapter
        key={chapter.id}
        id={chapter.id}
        label={chapter.title}
        onCheckboxChange={onCheckboxChange}
        checked={chapterIsSelected(chapter.id)}
      />
    ));

  return (
    <>
      <div id="chapters-card" className="p-1 mt-3">
        <h4 className="mb-3">
          {chapters.length
            ? `${I18n.t('activerecord.models.chapter.other')}:`
            : ''}
        </h4>
        {renderChapters()}
      </div>
    </>
  );
};

Chapters.defaultProps = {
  chapters: []
};

Chapters.propTypes = {
  onCheckboxChange: PropTypes.func.isRequired,
  chapterIsSelected: PropTypes.func.isRequired,
  chapters: PropTypes.arrayOf(PropTypes.object)
};

export default Chapters;
