import PropTypes from 'prop-types';
import React, { useCallback } from 'react';

const TextInput = ({
  id,
  meta,
  name,
  value,
  input,
  onBlur,
  onFocus,
  onChange,
  disabled,
  className,
  placeholder
}) => {
  const change = useCallback(
    (event) => {
      onChange?.(event);
      input?.onChange?.(event.currentTarget.value);
    },
    [input, onChange]
  );

  const blur = useCallback(
    (event) => {
      onBlur?.(event);
      input?.onBlur?.(event);
    },
    [input, onBlur]
  );

  const focus = useCallback(
    (event) => {
      onFocus?.(event);
      input?.onFocus?.(event);
    },
    [input, onFocus]
  );

  return (
    <>
      <input
        id={id}
        type="text"
        onBlur={blur}
        onFocus={focus}
        onChange={change}
        disabled={disabled}
        className={className}
        placeholder={placeholder}
        name={input?.name || name}
        value={input?.value || value}
      />
      {meta.error && meta.error.trim() && meta.touched && (
        <p className="field_with_errors">
          <label>{meta.error}</label>
        </p>
      )}
      {meta.submitError && !meta.dirtySinceLastSubmit && (
        <p className="field_with_errors">
          <label>{meta.submitError}</label>
        </p>
      )}
    </>
  );
};

TextInput.defaultProps = {
  meta: {},
  input: {},
  value: '',
  className: '',
  id: undefined,
  name: undefined,
  disabled: false,
  placeholder: '',
  onBlur: undefined,
  onFocus: undefined,
  onChange: undefined
};

TextInput.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  value: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  meta: PropTypes.objectOf(PropTypes.any),
  input: PropTypes.objectOf(PropTypes.any)
};

export default TextInput;
