import React from 'react';
import PropTypes from 'prop-types';

import ChildSelector from './childSelector';
import ParentsToolbar from './parentsToolbar';
import ParentSelector from './parentSelector';
import ChildrenToolbar from './childrenToolbar';

const ModelHierarchy = ({
  edit,
  create,
  remove,
  records,
  getLabel,
  nextLevel,
  subRecords,
  previousLevel,
  onChildSelect,
  loadingRecords,
  selectedRecord,
  onParentSelect,
  loadingSubRecords,
  recordType
}) => (
  <div className="row gx-3">
    <ParentsToolbar
      edit={edit}
      create={create}
      remove={remove}
      nextLevel={nextLevel}
      previousLevel={previousLevel}
      loadingRecords={loadingRecords}
      loadingSubRecords={loadingSubRecords}
      recordType={recordType}
    />
    <div className="col-10">
      <div className="row h-100">
        <ParentSelector
          records={records}
          getLabel={getLabel}
          onSelect={onParentSelect}
          onParentSelect={onParentSelect}
          selectedRecord={selectedRecord}
          disabled={loadingRecords || loadingSubRecords}
        />
        <ChildSelector
          getLabel={getLabel}
          records={subRecords}
          onSelect={onChildSelect}
          disabled={loadingRecords || loadingSubRecords}
        />
      </div>
    </div>
    <ChildrenToolbar
      create={create}
      selectedRecord={selectedRecord}
      recordType={recordType}
    />
  </div>
);

ModelHierarchy.defaultProps = {
  records: {},
  subRecords: {},
  selectedRecord: undefined
};

ModelHierarchy.propTypes = {
  edit: PropTypes.func.isRequired,
  selectedRecord: PropTypes.string,
  create: PropTypes.func.isRequired,
  remove: PropTypes.func.isRequired,
  getLabel: PropTypes.func.isRequired,
  nextLevel: PropTypes.func.isRequired,
  records: PropTypes.instanceOf(Object),
  onChildSelect: PropTypes.func.isRequired,
  previousLevel: PropTypes.func.isRequired,
  subRecords: PropTypes.instanceOf(Object),
  onParentSelect: PropTypes.func.isRequired,
  loadingRecords: PropTypes.bool.isRequired,
  loadingSubRecords: PropTypes.bool.isRequired,
  recordType: PropTypes.string.isRequired
};

export default ModelHierarchy;
