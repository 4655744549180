import axios from 'axios';
import React, { useState, useCallback, useEffect } from 'react';

import CustomerForm from './form';
import Customer from './customer';
import ModelHierarchy from '../commons/modelHierarchy';
import SuccessAlert from '../commons/alerts/success_alert';

const Customers = () => {
  I18n.locale = $('body').data('locale');
  const [formMode, setFormMode] = useState();
  const [customers, setCustomers] = useState();
  const [subCustomers, setSubCustomers] = useState();
  const [submitting, setSubmitting] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState();
  const [loadingCustomer, setLoadingCustomer] = useState(false);
  const [selectedCustomerId, setSelectedCustomerId] = useState();
  const [loadingCustomers, setLoadingCustomers] = useState(false);
  const [errorAlerts, setErrorAlerts] = useState();
  const [successAlerts, setSuccessAlerts] = useState();

  const objectifyCustomers = useCallback((records) => {
    const obj = {};
    records.forEach((record) => {
      obj[record.id] = record;
    });
    return obj;
  }, []);

  const loadCustomers = useCallback(
    (id = new URLSearchParams(window.location.search).get('id')) => {
      setLoadingCustomers(true);
      axios
        .get(`/customers${id ? `?id=${id}` : ''}`, {
          headers: { accept: 'application/json' }
        })
        .then(({ data }) => {
          setCustomers(objectifyCustomers(data));
          if (id) setSelectedCustomerId(`${id}`);
        })
        .finally(() => setLoadingCustomers(false));
    },
    [objectifyCustomers]
  );

  const loadCustomer = useCallback(
    (id) => {
      setLoadingCustomer(true);
      axios
        .get(`/customers/${id}`)
        .then(({ data }) => {
          setSubCustomers(objectifyCustomers(data.sub_customers));
          setSelectedCustomer(data);
        })
        .finally(() => setLoadingCustomer(false));
    },
    [objectifyCustomers]
  );

  const deleteCustomer = useCallback(() => {
    axios.delete(`/customers/${selectedCustomerId}`).then(() => {
      const obj = { ...customers };
      delete obj[selectedCustomerId];
      setCustomers(obj);
      setSelectedCustomerId(Object.keys(obj)[0]);
      setSuccessAlerts(I18n.t('customers.deleted'));
    });
  }, [customers, selectedCustomerId]);

  const saveExistingCustomer = useCallback(
    (values) => {
      setSubmitting(true);
      return axios
        .put(`/customers/${values.id}`, values)
        .then(({ data }) => {
          setCustomers({ ...customers, [data.id]: data });
          setSelectedCustomer({ ...selectedCustomer, ...data });
          setSubCustomers(objectifyCustomers(data.sub_customers));
          setFormMode(undefined);
          setErrorAlerts(undefined);
        })
        .catch((errors) => {
          setErrorAlerts(errors.response.data.error);
        })
        .finally(() => {
          setSubmitting(false);
          setSuccessAlerts(I18n.t('customers.updated'));
        });
    },
    [customers]
  );

  const saveNewCustomer = useCallback(
    (values) => {
      setSubmitting(true);
      return axios
        .post('/customers/', values)
        .then(({ data }) => {
          if (data.parent_id) {
            setSelectedCustomer({
              ...selectedCustomer,
              sub_customers: [...selectedCustomer.sub_customers, data]
            });
            setSubCustomers(objectifyCustomers(selectedCustomer.sub_customers));
          } else {
            setCustomers({ ...customers, [data.id]: data });
          }
          setFormMode(undefined);
          setErrorAlerts(undefined);
        })
        .catch((errors) => {
          setErrorAlerts(errors.response.data.error);
        })
        .finally(() => {
          setSubmitting(false);
          setSuccessAlerts(I18n.t('customers.created'));
        });
    },
    [customers, subCustomers, selectedCustomer]
  );

  useEffect(() => {
    if (!customers) loadCustomers();
  }, [customers, loadCustomers]);

  useEffect(() => {
    if (selectedCustomerId) {
      setFormMode(undefined);
      setErrorAlerts(undefined);
      loadCustomer(selectedCustomerId);
    }
  }, [loadCustomer, selectedCustomerId]);

  const onParentSelect = useCallback(
    (e) => {
      if (!submitting && selectedCustomerId !== e.target.value) {
        setSubCustomers({});
        setFormMode(undefined);
        setErrorAlerts(undefined);
        setSuccessAlerts(undefined);
        setSelectedCustomerId(e.target.value);
      }
    },
    [submitting, loadingCustomer, selectedCustomerId]
  );

  const onChildSelect = useCallback(
    (e) => {
      if (!submitting) {
        setFormMode(undefined);
        setErrorAlerts(undefined);
        setSuccessAlerts(undefined);
        setCustomers(subCustomers);
        setSubCustomers({});
        setSelectedCustomerId(
          e?.target?.value || `${Object.keys(subCustomers)[0]}`
        );
      }
    },
    [subCustomers, submitting, loadingCustomers]
  );

  const nextLevel = useCallback(() => {
    if (!submitting && Object.keys(subCustomers || {}).length) {
      onChildSelect();
    }
  }, [subCustomers, submitting, onChildSelect]);

  const previousLevel = useCallback(() => {
    if (!submitting && selectedCustomerId) {
      const customer = customers[selectedCustomerId];
      if (customer.parent_id) {
        setCustomers({});
        setSubCustomers(customers);
        loadCustomers(customer.parent_id);
      }
    }
  }, [customers, loadCustomers, submitting, selectedCustomerId]);

  const create = useCallback(
    (asChild = false) => {
      setErrorAlerts(undefined);
      setSuccessAlerts(undefined);
      if (!formMode) setFormMode(asChild ? 'createChild' : 'create');
    },
    [formMode]
  );

  const remove = useCallback(() => {
    if (
      !submitting &&
      selectedCustomerId &&
      confirm(I18n.t('customer_selector.confirm_delete'))
    )
      deleteCustomer();
  }, [selectedCustomerId, deleteCustomer, submitting]);

  const edit = useCallback(() => {
    if (selectedCustomer && !formMode) setFormMode('edit');
  }, [selectedCustomer, formMode]);

  const saveCustomer = useCallback(
    (values) =>
      values.id ? saveExistingCustomer(values) : saveNewCustomer(values),
    [saveExistingCustomer, saveNewCustomer]
  );

  const getLabel = useCallback((record) => record.name, []);

  return (
    <div>
      {successAlerts && <SuccessAlert successAlerts={successAlerts} />}
      <div className="card mb-5">
        <div className="card-body">
          <h1 className="mb-5">
            <i className="fas fa-user-tag me-2 fa-sm" />
            <span className="fa5-text">
              {I18n.t('activerecord.models.customer.other')}
            </span>
          </h1>
          <div className="mb-5">
            <div className="row justify-content-center">
              <div className="col-10">
                <div className="row">
                  <div className="col-6">
                    <h5>
                      {I18n.t('activerecord.models.customer.other')}
                      {loadingCustomers && (
                        <>
                          {' '}
                          <i className="fas fa-spinner fa-spin me-2 fa-sm" />
                        </>
                      )}
                    </h5>
                  </div>
                  <div className="col-6">
                    <h5>
                      {I18n.t('customers.subcustomers')}
                      {loadingCustomer && (
                        <>
                          {' '}
                          <i className="fas fa-spinner fa-spin me-2 fa-sm" />
                        </>
                      )}
                    </h5>
                  </div>
                </div>
              </div>
            </div>
            <ModelHierarchy
              edit={edit}
              create={create}
              remove={remove}
              records={customers}
              getLabel={getLabel}
              nextLevel={nextLevel}
              subRecords={subCustomers}
              previousLevel={previousLevel}
              onChildSelect={onChildSelect}
              onParentSelect={onParentSelect}
              loadingRecords={loadingCustomers}
              loadingSubRecords={loadingCustomer}
              selectedRecord={selectedCustomerId}
              recordType="customer"
            />
          </div>
          {formMode ? (
            <CustomerForm
              formMode={formMode}
              setFormMode={setFormMode}
              saveCustomer={saveCustomer}
              customer={selectedCustomer}
              selectedCustomerId={selectedCustomerId}
              errorAlerts={errorAlerts}
              setErrorAlerts={setErrorAlerts}
            />
          ) : (
            ''
          )}
          {!formMode && selectedCustomer ? (
            <Customer customer={selectedCustomer} />
          ) : (
            ''
          )}
        </div>
      </div>
    </div>
  );
};

export default Customers;
