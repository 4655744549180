import React from 'react';
import PropTypes from 'prop-types';

const ChildrenToolbar = ({ create, selectedRecord, recordType }) => (
  <div className="col">
    {selectedRecord && (
      <div
        aria-hidden="true"
        onClick={() => create(true)}
        className="btn btn-secondary btn-sm d-block mb-2"
        data-tippy-content={I18n.t(`${recordType}_selector.new`)}
      >
        <i className="fas fa-plus" />
      </div>
    )}
  </div>
);

ChildrenToolbar.defaultProps = {
  selectedRecord: undefined
};

ChildrenToolbar.propTypes = {
  selectedRecord: PropTypes.string,
  create: PropTypes.func.isRequired,
  recordType: PropTypes.string.isRequired
};

export default ChildrenToolbar;
