import PropTypes from 'prop-types';
import { Form, Field } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';
import React, { useState, useRef, useCallback } from 'react';

import SearchInstances from '../instances/search';
import SearchProjects from '../projects/search';
import TextInput from '../commons/formFields/textInput';
import SearchRequirementGroups from '../requirementGroups/search';
import ShowCustomers from '../requirementGroups/customers';
import ErrorAlert from '../commons/alerts/error_alert';

const CustomerForm = ({
  customer,
  formMode,
  setFormMode,
  saveCustomer,
  selectedCustomerId,
  errorAlerts,
  setErrorAlerts
}) => {
  const options = (data) =>
    data.map((details) => ({
      value: details.id,
      label: details.name
    }));

  const [selectedRequirementGroups, setSelectedRequirementGroups] = useState(
    []
  );

  const getInitialValues = useCallback(() => {
    const {
      id,
      name,
      instances,
      projects,
      requirement_groups: requirementGroups
    } = customer;

    let obj = {
      id,
      name,
      instances: options(instances),
      projects: options(projects),
      requirement_groups: options(requirementGroups)
    };

    if (formMode === 'createChild') {
      obj.id = undefined;
      obj.name = '';
      obj.parent_id = selectedCustomerId;
    }

    if (formMode === 'create') {
      obj = {};
    }

    return obj;
  }, [customer, selectedCustomerId]);

  const onSubmit = useCallback((values) => {
    const {
      id,
      name,
      parent_id: parentID,
      instances,
      projects,
      requirement_groups: requirementGroups
    } = values;

    const obj = {
      id,
      name,
      parent_id: parentID,
      project_ids: projects ? projects.map(({ value }) => value) : [],
      instance_ids: instances ? instances.map(({ value }) => value) : [],
      requirement_group_ids: requirementGroups
        ? requirementGroups.map(({ value }) => value)
        : []
    };
    return saveCustomer(obj);
  });

  const initialValues = useRef(getInitialValues(customer));
  const isParent = formMode === 'create' || formMode === 'edit';
  const isEditable = formMode === 'edit' || formMode === 'editChild';
  const isCreateModeWithRequirementGroups =
    formMode === 'create' && selectedRequirementGroups.length > 0;

  return (
    <div>
      <h4 className="mb-3">
        {I18n.t(isEditable ? 'customers.edit' : 'customers.new', {
          customer: customer?.name
        })}
      </h4>
      <Form
        onSubmit={onSubmit}
        initialValues={initialValues.current}
        render={({ invalid, submitting, handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            {errorAlerts && <ErrorAlert errorAlerts={errorAlerts} />}
            <div className="mb-3 field">
              <label className="form-label" htmlFor="customer-name">
                {I18n.t('name')}
              </label>
              <Field
                name="name"
                component={TextInput}
                className="form-control"
                validate={(value) =>
                  value ? undefined : I18n.t('required_field')
                }
              />
            </div>
            {isParent && (
              <div className="mb-3 field">
                <label
                  className="form-label"
                  htmlFor="project-requirement-groups"
                >
                  {I18n.t('activerecord.models.instance.other')}
                </label>
                <Field
                  multi
                  clearable
                  searchable
                  cacheOptions
                  name="instances"
                  className="form-control"
                  component={SearchInstances}
                />
              </div>
            )}
            <div className="mb-3 field">
              <label
                className="form-label"
                htmlFor="project-requirement-groups"
              >
                {I18n.t('activerecord.models.project.other')}
              </label>
              <Field
                multi
                clearable
                searchable
                cacheOptions
                name="projects"
                className="form-control"
                component={SearchProjects}
              />
            </div>
            <div className="mb-3 field csr-requirement-group">
              <label
                className="form-label"
                htmlFor="customer-requirement-groups"
              >
                {I18n.t('csr.requirements')}
              </label>
              <Field
                multi
                clearable
                searchable
                cacheOptions
                className="form-control"
                name="requirement_groups"
                component={SearchRequirementGroups}
                csrType="customers"
              />
              <OnChange name="requirement_groups">
                {(values) => setSelectedRequirementGroups(values)}
              </OnChange>
            </div>
            {isCreateModeWithRequirementGroups && (
              <ShowCustomers requirementGroups={selectedRequirementGroups} />
            )}
            <div className="float-end mb-3">
              <button
                type="button"
                disabled={submitting}
                onClick={() =>
                  setFormMode(undefined) && setErrorAlerts(undefined)
                }
                className="cursor-pointer btn btn-light me-3"
              >
                {I18n.t('cancel')}
              </button>
              <button
                type="submit"
                onClick={handleSubmit}
                disabled={invalid || submitting}
                className="cursor-pointer btn btn-primary"
              >
                {I18n.t('save')}
              </button>
            </div>
          </form>
        )}
      />
    </div>
  );
};

CustomerForm.defaultProps = {
  customer: {
    id: '',
    name: '',
    instances: [],
    projects: [],
    requirement_groups: []
  },
  selectedCustomerId: undefined,
  errorAlerts: undefined
};

CustomerForm.propTypes = {
  selectedCustomerId: PropTypes.string,
  formMode: PropTypes.string.isRequired,
  customer: PropTypes.instanceOf(Object),
  saveCustomer: PropTypes.func.isRequired,
  setFormMode: PropTypes.func.isRequired,
  errorAlerts: PropTypes.string,
  setErrorAlerts: PropTypes.func.isRequired
};

export default CustomerForm;
